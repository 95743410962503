import React, {lazy, Suspense} from 'react';
import { PictureProps } from './Picture/PictureProps';
const PictureComponent = lazy(() => import('./Picture/Picture'));
const renderLoader = <p>Loading</p>;
export interface BorderPictureProps
{
    picture: PictureProps;
}

const BorderPicture = (props: BorderPictureProps) => {
    return (
        <Suspense fallback={renderLoader}>
    <PictureComponent pictureClass="inline-block p-2 rounded bg-white " {...props.picture} ></PictureComponent>
    </Suspense>
    )
}

export default BorderPicture;