import React from 'react';
import Link, { LinkProps } from './Link';

export interface TagProps
{
    links: LinkProps[]
}

const Tags = (props: TagProps) => {
    return (<div className="m-2">
        {props.links.map((a,index) =>
        <Link classname="block" key={index} {... a} ></Link>)}
    </div>);
}

export default Tags;