import React from "react";
import IconTextLink from "./IconTextLink";
import IconLink from "./IconLink";
interface TopHeaderProps {}

const TopHeader = (props: TopHeaderProps) => {
  return (
    <div className="topheader-gradient text-white">
    <div className="container ml-auto mr-auto">
      <div className="flex">
        <div className="flex flex-grow">
          <div className="m-2">
            <IconTextLink
              iconurl="/assets/images/email.svg"
              iconClassName="inline-block"
              alt="Email Beauty Balance voor een afpraak"
              width="20"
              height="20"
              url="mailto:info@tiana.nu"
              target=""
              classname="text-black"
              library="fas"
              iconName="envelope"
              title="email voor een afspraak of informatie"
              name="info@tiana.nu"
            ></IconTextLink>
          </div>
          <div className="m-2">
            <IconTextLink
              iconurl="/assets/images/phone.svg"
              iconClassName="inline-block"
              alt="Bel Beauty Balance 06-41092602"
              width="20"
              height="20"
              url="tel:0641092602"
              target=""
              classname="m-1 text-black"
              library="fas"
              iconName="phone"
              title="bel voor een afspraak of informatie"
              name="06 - 410 92 602"
            ></IconTextLink>
          </div>
        </div>
        <div className="flex flex-row-reverse">
          <div className="m-2">
            <IconLink
            iconurl="/assets/images/facebook.svg"
            iconClassName="inline-block"
            alt="Facebook Beauty Balance"
            width="20"
            height="20"
              url="https://www.facebook.com/beautybalance2"
              target="_blank"
              classname="text-black"
              library="fab"
              iconName="facebook-f"
              title="Beauty balance op facebook"
              name="info@tiana.nu"
            ></IconLink>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TopHeader;
