import React, { useEffect, useState } from "react";
import HomePage from "./Pages/HomePage";
import PageFooter from "./Components/PageFooter";
import PageHeader from "./Components/PageHeader";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import ContactPage from "./Pages/ContactPage";
import getLandingPages from "./Services/landingpagesService";
import IplPage from "./Pages/IplPage";
import BehandelingenPage from "./Pages/BehandelingenPage";
import MassagesPage from "./Pages/MassagesPage";
import EpilerenPage from "./Pages/EpilerenPage";
import ShopPage from "./Pages/ShopPage";
import AanbiedingPage from "./Pages/AanbiedingPage";
import { getBehandelingPage } from "./Services/aanbiedingVanDeMaandService";
import { ProductCatalogItemProps } from "./Components/ProductCatalogItem";
import { CartItemProps } from "./Components/Cart/CartItem";
import { MenuItem } from "./Components/Header";
import { getMenuItems } from "./Services/menuItems";
import WinkelwagenPage, { WinkelwagenPageProps } from "./Pages/WinkelwagenPage";
import BedanktPage from "./Pages/Bedankt";
import PrijslijstPage from "./Pages/PrijslijstPage";
import ShopProductPage from "./Pages/ShopProductPage";

declare global {
  interface Window {
    gtag?: any;
  }
}

function trackPageView() {
  if (!window.gtag) {
    console.log("geen gtag");
  } else {
    window.gtag("event", "page_view", {
      page_title: window.document.title,
      page_location: window.location.pathname,
      send_to: "UA-176465225-1",
    });
  }
}
function App() {
  const addProductToCart = (product: ProductCatalogItemProps): void => {
    setWinkelWagen((w) => {
      if (w.cart.cartProducts) {
        const itemInCart = w.cart.cartProducts.find(
          (i) => i.product.id === product.id
        );
        if (itemInCart) {
          const products = w.cart.cartProducts.map((x) =>
            x.product.id === product.id ? { ...x, quantity: x.quantity + 1 } : x
          );
          return {
            ...w,
            cart: {
              cartProducts: products,
              addProductToCart: addProductToCart,
              removeProductFromCart: removeProductFromCart,
            },
            addProductToCart: addProductToCart,
            removeProductFromCart: removeProductFromCart,
          };
        } else {
          const products = [
            ...w.cart.cartProducts,
            {
              quantity: 1,
              product: product,
              addProductToCart: addProductToCart,
              removeProductFromCart: removeProductFromCart,
            },
          ];
          return {
            ...w,
            cart: {
              cartProducts: products,
              addProductToCart: addProductToCart,
              removeProductFromCart: removeProductFromCart,
            },
            addProductToCart: addProductToCart,
            removeProductFromCart: removeProductFromCart,
          };
        }
      } else {
        let newItems = new Array<CartItemProps>();
        newItems.push({
          product: product,
          quantity: 1,
          addProductToCart: addProductToCart,
          removeProductFromCart: removeProductFromCart,
        });
        return {
          ...w,
          cart: {
            cartProducts: newItems,
            addProductToCart: addProductToCart,
            removeProductFromCart: removeProductFromCart,
          },
          addProductToCart: addProductToCart,
          removeProductFromCart: removeProductFromCart,
        };
      }
    });
  };

  const removeProductFromCart = (product: ProductCatalogItemProps): void => {
    setWinkelWagen((w) => {
      if (w.cart.cartProducts) {
        const itemInCart = w.cart.cartProducts.find(
          (i) => i.product.id === product.id
        );
        if (itemInCart) {
          if (itemInCart.quantity - 1 === 0) {
            const products = w.cart.cartProducts.filter(
              (x) => x.product.id !== product.id
            );
            return {
              ...w,
              cart: {
                cartProducts: products,
                addProductToCart: addProductToCart,
                removeProductFromCart: removeProductFromCart,
              },
              addProductToCart: addProductToCart,
              removeProductFromCart: removeProductFromCart,
            };
          } else {
            const updatedProduct = {
              ...itemInCart,
              quantity: itemInCart.quantity - 1,
            };
            const products = w.cart.cartProducts.filter(
              (x) => x.product.id !== product.id
            );
            const updatedProducts = [...products, updatedProduct];
            return {
              ...w,
              cart: {
                cartProducts: updatedProducts,
                addProductToCart: addProductToCart,
                removeProductFromCart: removeProductFromCart,
              },
              addProductToCart: addProductToCart,
              removeProductFromCart: removeProductFromCart,
            };
          }
        } else {
          return { ...w };
        }
      } else {
        return { ...w };
      }
    });
  };

  const [menuItems] = useState<MenuItem[]>(() => getMenuItems());
  const [shopUrl, setShopUrl] = useState<string>(() => "/shop");
  const [winkelWagen, setWinkelWagen] = useState<WinkelwagenPageProps>(() => {
  
    try {
      const storage = localStorage.getItem("winkelwagen");
      if (storage) {
        const cartFromStorage = JSON.parse(storage);
        return {
          ...cartFromStorage,
          addProductToCart: addProductToCart,
          removeProductFromCart: removeProductFromCart,
        };
      } else {
        return {
          cart: {
            cartProducts: new Array<CartItemProps>(),
            addProductToCart: addProductToCart,
            removeProductFromCart: removeProductFromCart,
          },
        };
      }
    } catch {
      return { cart: { cartProducts: new Array<CartItemProps>() } };
    }
  });

  const itemsInCart = winkelWagen.cart.cartProducts.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );

  useEffect(() => {
    localStorage.setItem("winkelwagen", JSON.stringify(winkelWagen));
    let shopAantal = document.getElementById("shopAantal");
    if (shopAantal) shopAantal.innerText = itemsInCart.toString();
    if (itemsInCart > 0) {
      setShopUrl((u) => "/winkelwagen");
    }else {
      setShopUrl((u) => "/shop");
    }
  }, [winkelWagen, itemsInCart]);

  function createHeaderChildren(urlforCart: string) {
    return (
      <a href={urlforCart} className="bg-purple p-2 rounded relative m-2">
        <img
          className="fill-current"
          src="/assets/images/basket.png"
          width="30"
          height="30"
          title="toevoegen aan winkelmand"
          alt="toevoegen aan winkelwagen"
        />
        <span
          id="shopAantal"
          className="rounded-full h-4 w-4 bg-violet text-xs text-center text-white absolute -top-1 -right-1 "
        >
        </span>
      </a>
    );
  }
  return (
    <Router>
      <PageHeader
        menuItems={menuItems}
        children={createHeaderChildren(shopUrl)}
      ></PageHeader>
      <Routes>
        <Route path="/" element={<HomePage />}>
        </Route>
        <Route path="/#aanbieding" element={<HomePage />}>
          
        </Route>
        {getLandingPages().map((r) => (
          <Route key={r.title} path={r.url} element={ <LandingPage {...r} />}>
           
          </Route>
        ))}
        <Route path="/behandeling-van-de-maand" element={ <AanbiedingPage {...getBehandelingPage()} />}>
         
        </Route>
        <Route path="/gezichtsbehandeling/prijslijst" element={<PrijslijstPage />}>
          
        </Route>
        <Route path="/gezichtsbehandeling" element={<BehandelingenPage />}>
          
        </Route>
        <Route path="/epileren-verven-harsen" element={ <EpilerenPage />}>
         
        </Route>
        <Route path="/massages" element={ <MassagesPage />}>
         
        </Route>
        <Route path="/ipl-duurzaam-ontharen" element={<IplPage />}>
          
        </Route>
        <Route path="/epileren" element={ <EpilerenPage />}>
         
        </Route>
        <Route path="/shop/:productName" element={<ShopProductPage
            title="Producten"
            addProductToCart={addProductToCart}
            removeProductFromCart={removeProductFromCart}
          />}>
          
        </Route>
        <Route path="/shop" element={<ShopPage
            title="Producten"
            addProductToCart={addProductToCart}
            removeProductFromCart={removeProductFromCart}
          />}>
          
        </Route>
        <Route path="/contact" element={<ContactPage />}>
          
        </Route>
        <Route path="/winkelwagen" element={<WinkelwagenPage
            cart={winkelWagen.cart}
            addProductToCart={addProductToCart}
            removeProductFromCart={removeProductFromCart}
          />}>
          
        </Route>
        <Route path="/bedankt" element={ <BedanktPage />}>
        </Route>
      </Routes>
      <PageFooter></PageFooter>
    </Router>
  );
}

export default App;
