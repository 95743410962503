import React from "react";
import Picture from "../Components/Picture/Picture";
import Slogan from "../Components/Slogan";
import PriceList from "../Components/PriceList";
import {
  epilerenPrizes,
  harsenLichaamPrizes,
  harsenGelaatPrizes,
} from "../Services/epilerenPrices";
import TitleTag from "../Components/TitleTag";
import ImageTextBlock from "../Components/ImageTextBlock";
import epilerenText from "../Services/epilerenService";

interface EpilerenPageProps {}

const massageHeader = {
  images: [
    {
      media: "(min-width: 800px)",
      imageUrl: "/assets/images/massages-beauty-balance-header.webp",
      sourceType: "image/webp",
    },
    {
      media: "(max-width: 640px)",
      imageUrl: "/assets/images/massages-in-kampen.webp",
      sourceType: "image/webp",
    },
  ],
  defaultImageUrl: "/assets/images/massages-beauty-balance-header.jpg",
  alt: "Massages bij Beauty Balance vanaf 22,50",
};
const EpilerenPage: React.FC<EpilerenPageProps> = (
  props: EpilerenPageProps
) => {
  return (
    <>
      <TitleTag
        title="Epileren of harsen in Kampen"
        canonical="https://www.tiana.nu/epileren-verven-harsen"
        date="2020-12-16"
        description="Wenkbrouwen epileren, Harsen, oogbehandeling en verven in omgeving Zwolle, Motet 24 te Kampen"
      />
      <div className="w-full relative">
        <Picture
          imageClass="w-full"
          defaultImageUrl={massageHeader.defaultImageUrl}
          alt="Epileren, verven, harsen"
          images={massageHeader.images}
        ></Picture>
        <div className="absolute bottom-0 left-0 flex p-4 bg-lightviolet w-full">
          <h1 className="text-4xl pt-4 sm:text-3xl md:text-5xl m-2 font-Primary text-white font-normal">
            Epileren, verven en harsen
          </h1>
        </div>
      </div>
      <section className="container ml-auto mr-auto pb-8">
      <Slogan
          titleLevel="h2"
          titleClassName="text-center pt-10"
          title="Epileren"
        ></Slogan>
        <ImageTextBlock {...epilerenText()}></ImageTextBlock>
      </section>
      <article className="bg-grey">
        <Slogan
          titleLevel="h2"
          titleClassName="text-center pt-10"
          title="Wat is harsen?"
        ></Slogan>
        <section className="container mt-8 ml-auto mr-auto pb-8">
          Harsen is het verwijderen van overtollige lichaams- en gezichtsharen
          door middel van een kleverige substantie.
          <br />
          Harsen en waxen is hetzelfde. Een haar wordt met hars helemaal
          verwijderd.
          <br />
          Na het harsen zal het gemiddeld 3-8 weken duren voordat de haar weer
          is aangegroeid.
          <br />
          Harsen is een tijdelijke manier van haarverwijdering. Na het harsen
          zullen de haren dus weer aangroeien.
          <br />
          Dit aangroeien gebeurt altijd wel met een zachte (haar)punt zodat je
          in tegenstelling tot scheren, na harsen nooit harde stoppels zult
          hebben.
          <h3 className="text-xl font-bold text-secundary mt-4">Mogelijkheden harsen</h3>
          <ul>
            <li>Bikinilijn harsen</li>
            <li>Boven benen harsen</li>
            <li>Onderbenen harsen</li>
            <li>Oksels harsen</li>
            <li>Gezichtsbeharing harsen</li>
            <li>Rug &amp; schouders harsen</li>
            <li>Borst harsen</li>
          </ul>
        </section>
      </article>
      <section className="container ml-auto mr-auto my-6">
        <Slogan
          titleLevel="h2"
          titleClassName="text-center pt-10"
          title="Prijslijst"
        ></Slogan>
        <div className="lg:flex px-2 my-6">
          <div className="lg:w-1/3 mx-2 lg:mx-12 pricelist">
            <h2 className="font-Primary text-xl text-secundary font-normal">
              Gezicht ontharing
            </h2>
            <PriceList {...epilerenPrizes()} />
          </div>
          <div className="lg:w-1/3 mx-2 lg:mx-12  pricelist">
            <h2 className="font-Primary text-xl text-secundary font-normal">
              Lichaam ontharing
            </h2>
            <PriceList {...harsenLichaamPrizes()} />
          </div>
          <div className="lg:w-1/3 mx-2 lg:mx-12 pricelist">
            <h2 className="font-Primary text-xl text-secundary font-normal">
              Gelaat ontharing
            </h2>
            <PriceList {...harsenGelaatPrizes()} />
          </div>
        </div>
      </section>
    </>
  );
};

export default EpilerenPage;
