import React from "react";
import Picture from "../Components/Picture/Picture";
import PriceList from "../Components/PriceList";
import { massagesPrizes } from "../Services/massagesPriceList";
import Slogan from "../Components/Slogan";
import TitleTag from "../Components/TitleTag";

interface MassagesProps {}

const massageHeader = {
  images: [
    {
      media: "(min-width: 800px)",
      imageUrl: "/assets/images/massages-beauty-balance-header.webp",
      sourceType: "image/webp",
    },
    { media: "(max-width: 640px)", imageUrl: "/assets/images/massages-in-kampen.webp", sourceType: "image/webp" },
  ],
  defaultImageUrl: "/assets/images/massages-beauty-balance-header.jpg",
  alt: "Massages bij Beauty Balance vanaf 22,50",
};

const bottomHeader = {
  images: [
    {
      media: "(min-width: 800px)",
      imageUrl: "/assets/images/massages-beauty-balance-kampen-bw.webp",
      sourceType: "image/webp"
    },
    { media: "(max-width: 640px)", imageUrl: "/assets/images/massages-in-kampen-beauty-balance-640.webp", sourceType: "image/webp" },
  ],
  defaultImageUrl: "/assets/images/massages-in-kampen-beauty-balance-640.jpg",
  alt: "Massages bij Beauty Balance vanaf 22,50",
};

const MassagesPage = (props: MassagesProps) => {
  return (
    <>
      <TitleTag
        title="Massage in Kampen" 
        description="massage in Kampen, Nek, schouders, gezicht of hotstone, relaxmassages bij schoonheidsspecialiste Beauty Balance."
        canonical="https://www.tiana.nu/massages" 
        date="2021-05-07" ></TitleTag>
      <div className="w-full relative">
        <Picture
          imageClass="w-full"
          defaultImageUrl={massageHeader.defaultImageUrl}
          alt="Massages"
          width="100%"
          height="100%"
          images={massageHeader.images}
        ></Picture>
        <div className="absolute bottom-0 left-0 flex p-4 bg-lightviolet w-full">
          <h1 className="text-4xl pt-4 sm:text-3xl md:text-5xl m-2 font-Primary text-white font-normal">
            Massages
          </h1>
        </div>
      </div>
      <section className="container ml-auto mr-auto p-4">
        <p>
          <strong>Massage ter ontspanning; voor rug, nek, schouders of hotstone.</strong><br/>
          Een ontspannede massage voor gezicht, schouders, rug, nek of Hot Stone.
          Bij Beauty Balance is een schoonheidssalon waar u naast huidverzorging en
          huidverbetering ook terecht kunt voor <i>relaxmassages</i>.<br/>
          
        </p>

        <strong>
          Dit zijn de verschillende massages die mogelijk zijn in de salon:
        </strong>
        <br />
        <div className="mx-2 md:mx-12 pricelist">
        <Slogan titleLevel='h2' titleClassName="text-center pt-10" title="Prijzen"></Slogan>
          <PriceList {...massagesPrizes()} />
        </div>
        <br/>
        <p>
          De massage zorgt naast extra ontspanning ook voor een betere
          doorbloeding en activering van het lymfestelsel zodat de huid<br/>
          afvalstoffen afvoert.
        </p>
        
      </section>
      <div className="relative mt-20">
      <Picture
        imageClass="w-full"
        defaultImageUrl={bottomHeader.defaultImageUrl}
        alt="Massages bij tiana"
        width="100%"
        height="100%"
        images={bottomHeader.images}
         ></Picture>
         <div className="absolute top-0 bg-lightviolet w-full h-full"></div>
         </div>
    </>
  );
};

export default MassagesPage;
