import { PriceListProps } from "../Components/PriceList"

export const massagesPrizes = () : PriceListProps => {
    return {
        prices: [{ name: "Schouder, rug en nek massage", price: "€ 25,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "De Hot Stone rugmassage", price: "€ 27,50", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl"},
        { name: "Gezichtsbehandeling met gezichtsmassage", price: "€ 57,50", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Gezichtsbehandeling met bindweefselmassage", price: "€ 65,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" }
    ]
    }
}
