import { MenuItem, MenuItemGroup } from "../Components/Header";

export const getMenuItems = () : MenuItem[] =>{
    let items : MenuItem[] = new Array<MenuItem>();
    let behandelingGroups: MenuItemGroup[] = new Array<MenuItemGroup>();
    let behandelingitems: MenuItem[] = new Array<MenuItem>();
    behandelingitems.push({ name:"Epileren", title:"Epileren, verven, harsen", url:"/epileren-verven-harsen", children:  new Array<MenuItemGroup>()});
    behandelingitems.push({ name:"Jeugd", title:"Gezichtsbehandeling voor jeugd, acne", url:"/gezichtsbehandeling/jeugd", children:  new Array<MenuItemGroup>()});
    behandelingitems.push({ name:"Superior", title:"Superior gezichtsbehandeling", url:"/gezichtsbehandeling/superior", children:  new Array<MenuItemGroup>()});
    behandelingitems.push({ name:"Rug behandeling", title:"Rug behandeling", url:"/gezichtsbehandeling/rug", children:  new Array<MenuItemGroup>()}); 
    behandelingitems.push({ name:"Lash Volume Lift", title:"Lash Volume Lift", url:"/lash-volume-lift", children:  new Array<MenuItemGroup>()});
    behandelingitems.push({ name:"Brow Lamination", title:"Brow Lamination", url:"/gezichtsbehandeling/brow-lamination", children:  new Array<MenuItemGroup>()});
    
    let behandelingitemsGezicht2: MenuItem[] = new Array<MenuItem>();
    behandelingitemsGezicht2.push({ name:"Koud laser", title:"Koud laser", url:"/gezichtsbehandeling/koud-laser", children:  new Array<MenuItemGroup>()});
    behandelingitemsGezicht2.push({ name:"Beauty Angel", title:"Beauty Angel", url:"/gezichtsbehandeling/beauty-angel", children:  new Array<MenuItemGroup>()});
    behandelingitemsGezicht2.push({ name:"Microdermabrasie", title:"Microdermabrasie", url:"/gezichtsbehandeling/microdermabrasie", children:  new Array<MenuItemGroup>()});
    behandelingitemsGezicht2.push({ name:"Bindweefselmassage", title:"Bindweefselmassage", url:"/gezichtsbehandeling/bindweefselmassage", children:  new Array<MenuItemGroup>()});
    behandelingitemsGezicht2.push({ name:"Acne behandeling", title:"Acne behandeling", url:"/gezichtsbehandeling/acne", children:  new Array<MenuItemGroup>()});
    behandelingitemsGezicht2.push({ name:"Kruiden peeling", title:"Kruiden peeling", url:"/gezichtsbehandeling/beauty-angel-kruiden-peeling", children:  new Array<MenuItemGroup>()});
    behandelingitemsGezicht2.push({ name:"Collageendraden", title:"Collageendraden", url:"/gezichtsbehandeling/Collageendraden-behandeling", children:  new Array<MenuItemGroup>()});

    var gezichtGroup: MenuItemGroup = {
        headerName: "Gezicht",
        menuItems: behandelingitems
    }
    var gezichtGroup2: MenuItemGroup = {
        headerName: "Gezicht Speciaal",
        menuItems: behandelingitemsGezicht2
    }
    let behandelingitems2: MenuItem[] = new Array<MenuItem>();
        behandelingitems2.push({ name:"Rug, nek massage", title:"Rug en nek massage", url:"/massages/rug-nek-massage", children:  new Array<MenuItemGroup>()});
        behandelingitems2.push({ name:"Hot Stone massage", title:"Hot stone", url:"/massages/hot-stone-massage", children:  new Array<MenuItemGroup>()});
    behandelingitems2.push({ name:"Bindweefselmassage", title:"Bindweefselmassage", url:"/massages/bindweefsel-massage", children:  new Array<MenuItemGroup>()});
    var massageGroup: MenuItemGroup = {
        headerName: "Massages",
        menuItems: behandelingitems2
    }
    let alleItems: MenuItem[] = new Array<MenuItem>();
    alleItems.push({ name:"Alle behandelingen", title:"Alle behandelingen", url:"/gezichtsbehandeling", children:  new Array<MenuItemGroup>()});
    alleItems.push({ name:"Prijslijst", title:"Prijslijst van de behandelingen", url:"/gezichtsbehandeling/prijslijst", children:  new Array<MenuItemGroup>()});
    alleItems.push({ name:"Behandeling van de maand", title:"Behandeling van de maand", url:"/behandeling-van-de-maand", children:  new Array<MenuItemGroup>()});

    var overzichtGroup: MenuItemGroup = {
        headerName: "Overzicht",
        menuItems: alleItems
    }
    behandelingGroups.push(gezichtGroup);
    behandelingGroups.push(gezichtGroup2);
    behandelingGroups.push(massageGroup);
    behandelingGroups.push(overzichtGroup);
    items.push({ name:"Home", title:"Home", url:"/", children: new Array<MenuItemGroup>() });
    items.push({ name:"Behandelingen", title:"Alle behandelingen op een rij", url:"/gezichtsbehandeling", children: behandelingGroups });
    items.push({ name:"Collageendraden", title:"Collageendraden", url:"/gezichtsbehandeling/Collageendraden-behandeling", children:  new Array<MenuItemGroup>()});
    items.push({ name:"Fibroblasting", title:"Fibro blasting", url:"/fibro-blasting", children: new Array<MenuItemGroup>() }); 
    items.push({ name:"IPL", title:"IPL", url:"/ipl-duurzaam-ontharen", children: new Array<MenuItemGroup>() }); 
    items.push({ name:"Shop", title:"Onze producten", url:"/shop", children: new Array<MenuItemGroup>() });
    items.push({ name:"Visie", title:"Visie van Beauty Balance", url:"/visie-beauty-balance", children: new Array<MenuItemGroup>() });
    items.push({ name:"Contact", title:"Contact met Beauty Balance", url:"/contact", children: new Array<MenuItemGroup>() });
    return items;
}