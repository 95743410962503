import React , {lazy, Suspense} from "react";
import { ParallaxProps } from "./ParallaxProps";

const PictureComponent = lazy(() => import('../Picture/Picture'));
const renderLoader = <p>Loading</p>;

const Parallax: React.FC<ParallaxProps> = (props: ParallaxProps) => {
  let width = props.width ?? "100%";
  let height = props.height ?? "100%";
  return (
    <div className="relative">
        {props.children}
        <Suspense fallback={renderLoader}>
        <PictureComponent width={width} height={height} imageClass="" {...props}></PictureComponent>
        </Suspense> 
        
    </div>
  );
};

export default Parallax;
