import { LandingPageProps } from "../Pages/LandingPage";
import { microdermabrasiePrizes } from "./epilerenPrices";

const generalHeader = {
    images: [{ media: "(min-width: 800px)", imageUrl: "/assets/images/beauty-balance-welkom-800.webp", sourceType: "image/webp" },
    { media: "(max-width: 799px)", imageUrl: "/assets/images/beauty-balance-welkom-300.webp", sourceType: "image/webp" }],
    defaultImageUrl: "/assets/images/beauty-balance-welkom-800.jpg",
    alt: "Microneedling"
}

const massageHeader = {
    images: [{ media: "(min-width: 800px)", imageUrl: "/assets/images/massages-beauty-balance-header.webp", sourceType: "image/webp" },
    { media: "(max-width: 640px)", imageUrl: "/assets/images/massages-in-kampen.webp", sourceType: "image/webp" }],
    defaultImageUrl: "/assets/images/massages-beauty-balance-header.jpg",
    alt: "Massages bij Beauty Balance vanaf 22,50"
}

const getLandingPages = (): LandingPageData[] => {
    let pages: LandingPageData[] = new Array<LandingPageData>();
    pages.push({
        url: "/schoonheidsspecialisten-kampen",
        title: "Beauty Balance zijn schoonheidsspecialisten te Kampen",
        canonical: "https://www.tiana.nu/schoonheidsspecialisten-kampen",
        description: "Schoonheidsspecialist in Kampen, Schoonheidssalon voor uw beauty behandelingen",
        content: `Wij bieden ontspannende en huidverbeterende behandelingen voor alle huidtypes. Iedere huid is immers uniek.
        <p>Ook voor harsen, epileren en verven bent u bij Beauty Balance als uw schoonheidsspecialisten aan het juiste adres.<br/>
        <p>
        Graag verwelkomen wij u als klant bij Beauty Balance, schoonheidsspecialisten, Motet 24 Kampen,</p>
        `
    });
    pages.push({
        topImage: generalHeader,
        url: "/schoonheidsspecialisten-ijsselmuiden",
        title: "IJsselmuiden - schoonheidsspecialist in Kampen",
        description: "",
        content: `Over de brug bij Ijsselmuiden is het nog geen 10 minuten rijden naar Kampen.<br/>
        Wij bieden ontspannende en huidverbeterende behandelingen voor alle huidtypes. Iedere huid is immers uniek.
        <p>Ook voor harsen, epileren en verven bent u bij Beauty Balance als uw schoonheidsspecialisten aan het juiste adres.<br/>
        <p>
        Graag verwelkomen wij u als klant bij Beauty Balance, schoonheidsspecialisten, Motet 24 Kampen,</p>
        `
    });
    pages.push({
        topImage: massageHeader,
        url: "/schoonheidsspecialisten-zwolle",
        canonical: "https://www.tiana.nu/schoonheidsspecialisten-zwolle",
        title: "Schoonheidsspecialist Zwolle - Beauty Balance",
        description: "In omgeving Zwolle is Beauty Balance de schoonheidsspecialist van de regio. Uw Beautysalon met behandelingen voor alle huidtypes",
        content: `In de omgeving van Zwolle bieden wij ontspannende en huidverbeterende behandelingen voor alle huidtypes. Iedere huid is immers uniek.
        <p>Vanaf Stadshagen is onze praktijk aan de Motet 24 te Kampen snel en eenvoudig te bereiken.
        <p>Ook voor harsen, epileren en verven bent u bij Beauty Balance als uw schoonheidsspecialisten aan het juiste adres.<br/>
        <p>Graag verwelkomen wij u als klant bij Beauty Balance, schoonheidsspecialisten Kampen.</p>
        `
    });

    pages.push({
        topImage: generalHeader,
        url: "/gezichtsbehandeling/collageendraden-behandeling",
        title: "Collageendraden behandeling",
        description: "collageendraden behandelingen",
        content: `<ul><li>Oppervlaktereiniging</li>
        <li>Dieptereiniging</li>
        <li>Collageendraden</li>
        <ul>
        <p>Prijs afhankelijk van aantal te gebruiken draden, draden a 15,- per stuk, bij intake kan pas het aantal draden worden bepaald<br/>
        <b>Naverzorging €25,00 + €15,00 per draad</b></p>
        <p><h2>Wat zijn de DS V Line Collageendraden</h2>
        DS V-Line Collageendraden is een cosmetische behandeling, waarbij door een speciale techniek de collageendraden aangebracht worden in de huid!</p>
        <br/>De originele gepatenteerde DS V-Line collageendraden tezamen met het unieke stamcelserum boordevol goede werkstoffen, zorgen voor meer volume in het gezicht, dit door de absorptie van het collageen in een diepere huidlaag, waardoor ook de diepere rimpels worden aangepakt!
        <br/>De huid wordt zichtbaar strakker en steviger en versterkt de elasticiteit van de huid en zijn huidbarrière!</p>
        <p><h2>Voordelen van de DS V-Line collageendraden?</h2>
        <ol>
        <li>De natuurlijke gezichtsuitdrukking blijft behouden</li>
        <li>Direct zichtbaar resultaat</li>
        <li>Stopt het verouderingsproces</li>
        <li>Versterkt en balanceert de collageen niveau’s in het algemeen</li>
        <li>Verbetert de huidkwaliteit en ondersteunt deze</li>
        <li>Versterking van de huid barrière</li>
        <li>Naaldloos / pijnvrij</li>
        <li>Beste resultaat in kuurverband, ca 4 behandelingen voor optimaal resultaat</li>
        <li>Effectduur: ongeveer 3-6 maanden</li>
        <li>Geschikt voor alle huidtypes</li>
        <li>De Collageendraden zijn super te combineren met een dermaplaning-behandeling of een microneedling behandeling</li>
        </ul>
        <strong>Toepassingsgebieden van de DS V-Line Collageendraden</strong>
        <ul><li>Gezicht; denk aan fronsrimpels, voorhoofd, kraaienpootjes, boven- en onderoogleden, neuslippenplooi, (barcode)</li>
        <li>Hals en decolleté</li>
        <li>Handen</li>
        </ol>
        <p><h2>Wat is Collageen eigenlijk?</h2>
        <br/>Collageen is een heel belangrijk structuureiwit, dat stevigheid geeft aan het lichaam en aan de huid. We hebben dit stofje in onze botten, pezen, haren en nagels, maar dus ook in onze huid. Collageen komt van het Griekse ‘kolla’, dat samenbinden of lijm betekent. Het houdt organen en weefsels als het ware op hun plek.Collageen én het stofje elastine zijn een soort pilaren die de huid stevig houden. Als die pilaren omvallen, krijg je rimpels. Die afbraak kan komen door onder andere veroudering, roken, zon, suikers (glycatie) en luchtvervuiling ... Je lichaam kan niet alleen collageen afbreken, het maakt dit stofje ook aan – ook als je ouder bent. Maar op den duur gaat de afbraak van collageen helaas sneller dan de aanmaak.</p>`,
    });
    pages.push(
        {
            topImage: generalHeader,
            url: "/huidscan",
            title: "Huidscan",
            description: "",
            content: `
    <p>Met behulp van speciale apparatuur kan ik u zelf laten zien, wat u met het blote oog niet altijd kan zien.<br/>
    Bijvoorbeeld:
<ul>
<li>Zonneschade</li>
<li>Pigmentatie</li>
<li>Vroegtijdige veroudering</li>
<li>Onrustige moedervlekken</li>
<li>Onrust in de huid</li>
</ul>
 <b>Een huidscan kost € 25,00</b>
<p>
Als we wachten tot dit zichtbaar wordt, is de schade vaak groter dan ons lief is.<br/>
'Geef mij de tijd voordat u de tijd ziet.'<br/>
Het belang van passende huidverzorging wordt op deze manier echt duidelijk!</p>`});



    pages.push({
        topImage: massageHeader,
        url: "/massages/rug-nek-massage",
        description: "Rug en Nek Massages in Kampen door Beauty Balance, Tiana behandelt uw gezicht, schouders, rug en nek",
        title: "Rug en Nek Massages",
        content: `<h2>Ontspannende massages voor gezicht, schouders, rug en nek verzorgts schoonheidsspecialist Beauty Balance in Kampen</h2>
    <br/>De massage zorgt naast extra ontspanning ook voor een betere doorbloeding en activering van het lymfestelsel zodat de huid afvalstoffen afvoert.
    <p>Dit zijn de verschillende massages die mogelijk zijn in de salon:<br/>
    Schouder, rug en nek massage € 25,00<br/>
    De Hot Stone rugmassage € 27,50<br/>
    Gezichtsbehandeling met gezichtsmassage € 55,00<br/>
    Gezichtsbehandeling met ijsbollenmassage € 60,00<br/>
    Gezichtsbehandeling met bindweefselmassage € 60,00</p>`
    });

    pages.push({
        topImage: massageHeader,
        url: "/massages/rug-nek-massage",
        description: "Rug en Nek Massages in Kampen door Beauty Balance, Tiana behandelt uw gezicht, schouders, rug en nek",
        title: "Rug en Nek Massages",
        content: `<h2>Ontspannende massages voor gezicht, schouders, rug en nek verzorgts schoonheidsspecialist Beauty Balance in Kampen</h2>
        <br/>De massage zorgt naast extra ontspanning ook voor een betere doorbloeding en activering van het lymfestelsel zodat de huid afvalstoffen afvoert.
        <p>Dit zijn de verschillende massages die mogelijk zijn in de salon:<br/>
        Schouder, rug en nek massage € 25,00<br/>
        De Hot Stone rugmassage € 27,50<br/>
        Gezichtsbehandeling met gezichtsmassage € 55,00<br/>
        Gezichtsbehandeling met ijsbollenmassage € 60,00<br/>
        Gezichtsbehandeling met bindweefselmassage € 60,00</p>`
    })
    pages.push(
        {
            url: "/gezichtsbehandeling/koud-laser",
            title: "Koud laser",
            description: "",
            content: `Koud laser, ook wel de Beauty Booster genoemd,<br/>is een intensieve huidverbeterende behandeling (veilig en pijnloos) waarbij de actieve werkstof Hyaluronzuur diep in de huid wordt gesluisd en vastgezet met behulp van de koud laser.
            <p>Deze risicoloze ontspannende behandeling is geschikt voor gezicht, hals, decolleté en handen.
            Er is geen herstelperiode nodig en het resultaat is direct zichtbaar. Uw huid krijgt meer volume en een stralende teint!
            Het beste resultaat met de koud laser wordt bereikt met zes tot acht behandelingen in drie of vier weken. 
             </p>
            <h3>Cymedics Light (koud laser) is ontwikkeld om de huid in korte tijd een enorme boost te geven.</h3>
             
            <p>Wat mag u verwachten na een cymedics light behandeling.<br/>
            • Direct resultaat na 1 behandeling<br/>
            • Vermindering van fijne lijntjes en rimpels<br/>
            • Betere teint<br/>
            • Minder roodheid<br/>
            • Meer glans<br/>
            • Meer volume</p>
            `
        }
    );
    pages.push({
        url: "/gezichtsbehandeling/beauty-angel",
        title: "Beauty Angel",
        description: "",
        content: `De Beauty Angel staat voor een innovatief concept van licht- en wellness therapie, dat de wens naar een zachte en natuurlijke huidverjonging vervult. Het ‘beauty’ light van de collageen lampen wordt door de huidcellen opgenomen en zorgt voor een verhoging van de lichaamseigen productie van collageen, elastine en hyaluron. Bij regelmatig gebruik worden rimpeltjes gereduceerd, de huid zichtbaar strakker en het totale huidbeeld verbeterd. De gevolgen van huidveroudering worden met deze behandeling op een natuurlijke wijze verminderd.
         
        <h2>Bewezen werking</h2>
        <p>De behandeling met licht met een speciale frequentie wordt sinds vele jaren succesvol in de geneeskunde en cosmetica branche gebruikt; talloze wetenschappelijke studies bewijzen dan ook de werkzaamheid. De effectiviteit van Beauty Angel wordt door een omvangrijke studie in en huidfysiologisch instituut alsook door een wetenschappelijk project van de universiteit Ulm bevestigd. Na slechts een maand heeft 80% van de testpersonen een zachtere huid en meer dan 60% meerdere zichtbare effecten vastgesteld. Na de tweede maand zijn deze resultaten nogmaals duidelijk verbeterd. De gebruikers hadden een strakkere huid, minder rimpeltjes, verfijnde poriën en een regelmatiger huidbeeld.
         <br/>
        Directe resultaten:<br/>
        •	Zachte en soepele huid<br/>
        •	Vochtigheidsgehalte in de huid wordt verbeterd<br/>
        •	Meer lichtintensiteit voor de huid<br/>
        •	Fijnere poriën en een beter huidbeeld<br/>
        •	Ontspanning en goede gezondheid<br/>
         </p>
        Resultaten op lange termijn:<br/>
        •	Het bindweefsel wordt met collageen, elastine en hyaluron opgevuld<br/>
        •	Vermindering van rimpeltjes in het gezicht en decolleté<br/>
        •	Ouderdoms- en pigmentvlekken verbleken<br/>
         <p>
        De ideale behandeling
        De meeste gebruikers spreken al na de eerste behandeling van een ontspanningseffect en een frissere en zachtere huid. Voor de beste resultaten zal de Beauty Angel behandeling regelmatig en over een langer tijdsbestek ondergaan moeten worden. Door de aanvulling met hoogwaardige huidverzorgingsproducten wordt de werking van de behandeling verhoogd. Daarnaast draagt een gezonde levensstijl ook zeker bij aan het uiteindelijke resultaat.
         </p>
        De behandeling<br/>
        Een Beauty Angel behandeling duurt ongeveer 15-20 minuten. Voor dat uw huid blootgesteld wordt aan het licht van de Beauty Angel, wordt uw huid gereinigd. Na deze reiniging worden twee serums op de huid aangebracht die de werking van de Beauty Angel stimuleren. Nu is uw huid er klaar voor om aan het licht blootgesteld te worden. Ter afsluiting van de behandeling wordt er een dag- of nachtcrème aangebracht. Tussen de individuele behandelingen bevelen we een pauze van 48 uur aan. Ter bescherming van het felle licht, worden uw ogen tijdens de behandeling beschermd, krijgt u tijdens de behandeling oogbescherming.
         <p>
        Is de behandeling voor iedereen geschikt?<br/>
        De Beauty Angel behandeling is voor alle huidtypes geschikt. Personen met een lage bloeddruk, zwangere vrouwen, personen met epilepsie, personen die lijden aan een verhoogde gevoeligheid voor licht of personen die medicijnen gebruiken, die een verhoogde lichtgevoeligheid veroorzaken ( bijv. Antibiotica of Sint- Janskruid), moeten helaas afzien van de collageenbehandeling. Bij het eerste gebruik is een minimale dosis aan te bevelen, om de verdraagzaamheid te testen.
         </p>
        Is het rood licht-gebruik met zonlicht te vergelijken?<br/>
        Nee, de Beauty Angel maakt gebruik van licht met een speciale frequentie die praktisch geen UV bevat. De huid zal dus ook niet bruinen door de behandeling.
         <p>
        Natuurlijke regeneratie & verjonging van de huid
         
        Wat zijn de oorzaken van huidveroudering?
        Huidveroudering wordt door meerdere factoren veroorzaakt. Vanaf het 25e levensjaar neemt de verzorging van de opperhuid met voedingsstoffen af. Dit leidt tot een vermoeide, val en onregelmatige teint. Naarmate men ouder wordt vertraagt de stofwisseling van de fibroblast-cellen (cellen in het bindweefsel), waardoor het collageengehalte van de huid per levensjaar gemiddeld met 1% daalt. Aangezien collageen de belangrijkste ‘vulling’ van het bindweefsel is, verslapt de huid. De verminderde celregeneratie leidt bovendien tot vochtverlies, vermindering van de elasticiteit en tenslotte tot rimpelvorming.
        Hoe zorgt licht voor huidverjonging?
        De Beauty Angel gebruikt licht met een bepaalde frequentie om de huid op een natuurlijke wijze te verjongen. Het zogenoemde Beauty Light wordt door de huidcellen opgenomen en verhoogt daar de lichaamseigen productie van collageen, elastine en hyaluron. Hierdoor worden rimpeltjes gereduceerd, de huid zichtbaar strakker en het totale huidbeeld verbeterd. Het resultaat is een strakkere en gezondere huid.
        </p>`
    })

    pages.push({
        url: "/gezichtsbehandeling/bindweefselmassage",
        title: "Bindweefselmassage",
        description: "Cosmetische Bindweefselmassage is een pittige behandeling",
        content: `<p>Cosmetische Bindweefselmassage is een pittige behandeling en zeker niet altijd pijnloos. Toch is dit één van de populairste behandelingen. Wat is het nut van bindweefselmassage en kan het geen kwaad?.</p>
         <br/>
         <h2>Bindweefsel</h2>
         Bindweefsel kun je vergelijken met het cement tussen de stenen van een huis. Door ons hele lichaam verbindt en ondersteunt het organen, gewrichten en spieren. Bindweefsel leidt ook bloedvaten en zenuwen naar de organen en beschermt tegen infecties. Ook hebben de cellen in het bindweefsel een grote rol bij het wondgenezingsproces. Het onderhuidse bindweefsel isoleert warmte, slaat energie op en is verantwoordelijk voor het transport van zuurstof, voedings- en afvalstoffen.
         Een jong, strak en rimpelvrij gezicht bestaat van binnen uit goed bindweefsel. Dit bindweefsel zit vol collageen en elastine. Tijdens het verouderen van de huid wordt er minder collageen en elastine in het bindweefsel aangemaakt. De rek gaat er als het ware uit. De huid wordt slapper en dat heeft tot gevolg: rimpels!
         <h3>De bindweefsel massage</h3>
         <p>Een bindweefselmassage is een uitstekende manier om de huidconditie te verbeteren. De behandeling verhoogt de doorbloeding en activeert de huidstofwisseling. De bindweefselmassage is uitermate geschikt om littekenweefsel los te maken en om een verslapte huid, een slecht doorbloede huid, een verouderde huid, rimpels of acne te verbeteren en/of te verminderen.
         Door de stevige massagegrepen in het bindweefsel wordt de aanmaak van collageen sterk gestimuleerd. Door de huid goed te doorbloeden, is de huid beter in staat om voedingstoffen op te nemen. Bij een slechte doorbloeding kunnen o.a. rimpels, verklevingen of acne ontstaan. Dit betekent dat de celwanden bijna niet in staat zijn om voedingsstoffen op te nemen en afvalstoffen af te voeren. Door de Bindweefselmassage wordt de circulatie verbeterd en daardoor wordt de huidconditie geoptimaliseerd. Door deze aanpak krijgt u een gezondere en stevigere huid met een gezonde kleur.
         <ul>Met deze massage worden o.a. de volgende effecten bereikt:
         <li>- Verbetering van de huidconditie</li>
         <li>- Stimulering van de huid doorbloeding</li>
         <li>- Reductie van stress door het opheffen van onderhuidse verklevingen</li>
         <li>- Opnameverbetering van werkstoffen</li>
         <li>- Bevordering van de celdeling</li>
         <li>- Versteviging en nieuwe aanmaak van bindweefsel</li>
         <li>- Verbetering van de huidstructuur en huidteint</li>
         <li>- Vermindering van rimpels, kraaienpootjes en littekens</li>
         </ul>
         </p> 
         <h3>Behandelfrequentie</h3>
         <p>
         Afhankelijk van de doelstelling, uw huidconditie en de behandelindicatie wordt de behandelfrequentie vastgesteld. Meestal zijn zes tot twaalf behandelingen nodig. De bindweefselmassage geeft in kuur vorm het beste effect. Naarmate de kuur vordert, wordt het weefsel soepeler en gaat de structuur van de huid er steeds beter uitzien. De huid raakt beter doorbloed en afvalstoffen worden sneller afgevoerd.
         Een kuur bestaat uit 1 x per week bindweefselmassage gedurende drie weken, met daarna een rustpauze van drie weken (de massage werkt in deze drie weken nog door). Hierna weer drie weken, 1 x per week behandelen. Indien nodig kan de bindweefselmassage worden uitgebreid naar twaalf behandelingen.
         Om het effect te onderhouden wordt aanbevolen om vervolgens 1 x per 6 weken terug te komen of door tijdens de regelmatige gezichtsbehandeling de klassieke massage te vervangen door de bindweefselmassage. De gezichtsbehandeling duurt dan 15 minuten langer en hier wordt € 15 extra voor gerekend.
         </p>
         <h2>Cosmetische indicaties</h2>
         (Indicaties die baat hebben bij bindweefselmassage)<br/>
         <ul>
         <li>- degeneratie van de huid (teruggang in conditie en functie van weefsels)</li>
         <li>- atrofische huid (verschrompeling van de huid door celvermindering)</li>
         <li>- sebostase huid (droge huid / geringe talgproductie)</li>
         <li>- seborrhoe huid (vette huid / overproductie van talg)</li>
         <li>- voorbehandeling van cosmetisch-chirurgische ingrepen</li>
         <li>- congestieve huid (rode gestuwde huid)</li>
         <li>- huidveroudering</li>
         <li>- rimpels en plooien</li>
         <li>- verslapte huid</li>
         <li>- rokershuid</li>
         <li>- slecht doorbloede huid</li>
         <li>- acne (zonder actieve ontsteking)</li>
         <li>- couperose</li>
         <li>- rosacea (zonder actieve ontsteking)</li>
         <li>- preventief voor alle huidtype</li>
        </ul>
        <h3>Contra-indicaties</h3>
        (Indicaties waarbij geen bindweefselmassage mag worden uitgevoerd)<br/>
        <ul>
        <li>- koorts</li>
        <li>- hartklachten</li>
        <li>- bloeddruk problemen</li>
        <li>- algehele malaise</li>
        <li>- hemofilie</li>
        <li>- kwaadaardige tumoren</li>
        <li>- defecte huid</li>
        <li>- chronisch eczeem</li>
        <li>- na de 7e maand zwangerschap</li>
        <li>- specifieke medicatie, zoals:</li>
        <li>- middelen met Corticosteroïden</li>
        <li>- bloedverdunnende middelen</li>
        </ul>
        `
    });
    pages.push({
        url: "/gezichtsbehandeling/acne",
        title: "Acne Behandeling",
        description: "",
        content: `<h2>Wat is ACNE</h2>
        <p>Acne is een veelvoorkomend, vervelend huidprobleem waarbij de talgklieren verstopt en/of ontstoken raken.<br/>
        Er bestaan verschillende vormen Acne. Je kunt er last van hebben op het gezicht en lichaam.<br/>
        Acne komt het meeste voor bij jongeren rond de puberteit maar er bestaan ook vormen van Acne die ontstaan op latere leeftijd.<br/>
        <h3>Hoe kan Acne behandeld worden?</h3>
        Bij Beauty Balance in Kampen hebben we speciale <strong>huidverbeterende <a href="/gezichtsbehandeling">behandelingen</a> om Acne</strong> te behandelen.<br/>
        Deze behandeling verbetert het uiterlijk van de huid met grote sprongen, we reinigen je huid intensief en de behandeling die stimuleert het herstel van jouw huid.<br/>
        De onzuiverheden op jouw huid pakken we grondig aan. De acne behandeling vermindert de overmatige afscheiding van talg. De Acne komt zo tot rust en verbetert de huidstructuur.
        We kijken altijd samen met jouw om te kijken wat de beste behandeling is voor jouw huid</p>`
    });
    pages.push({
        url: "/naaldloze-mesotherapie",
        title: "Naaldloze mesotherapie",
        description: "",
        content: `
    Door het behandelen van de huid met Ultrasound worden de tussenruimtes tussen de cellen wijder, waardoor afvalstoffen beter afgevoerd kunnen worden.
    Dat is zeer wenselijk, aangezien afvalstoffen vocht aan de huid onttrekken. Na een ultrageluidbehandeling kan de huid zelf weer vocht aannemen en daarmee alleen al een voller uiterlijk krijgen. Dit proces zorgt er niet alleen voor dat de afvoer van de afvalstoffen zicht verbetert, echter de aanvoer van zuurstof en voedingsstoffen naar de cellen wordt ook verbeterd. De huid wordt hierdoor geactiveerd om dikker, steviger, elastischer en beter doorbloed te worden. Dit is mede mogelijk, doordat met behulp van dit apparaat hooggeconcentreerde serums met werkstoffen waar de huid een tekort aan heeft gekregen, tot diep in de huid ingesluisd kunnen worden.
    Enzymen worden met behulp van Ultrageluidsgolven dieper in de huid gebracht.
    Deze huididentieke stoffen, belangrijk voor de opbouw en verzorging van cellen, gaan daar werken waar de celdeling van de huid plaatsvindt. Resultaat: nieuwe aanmaak van cellen, gevolg hiervan is betere kwaliteit van het bindweefsel, collageen en elastine, waardoor rimpels vervagen en de huid gladder wordt.
    `
    });
    pages.push({
        url: "/gezichtsbehandeling/hoogfrequent",
        title: "Hoogfrequent",
        description: "",
        content: `Door middel van gebruik van een Hoogfrequent apparaat wordt met behulp van een glaselektrode ozonlicht aangemaakt wat de huid desinfecteert.
    <br/>
    Tijdens het gebruik van ontstaat er verrijkt zuurstof die in staat is om door de huid heen te dringen, waardoor een optimale omgeving voorde huid wordt gecreerd. De hoge frequentie masseert de huid waardoor de opname van actieve werkstoffen wordt verhoogd en de bloedsomloop en de stofwisseling wordt gestimuleerd van de huidcellen. Hierdoor wordt de huid geprikkeld om te "verjongen" en zich te herstellen.
    <br/>Lijntjes en rimpels worden oppervlakkiger en de verouderde huid ziet er weer jonger uit. Ook een beschadigde huid wordt weer mooi, gezond en egaal`
    });
    pages.push({
        url: "/gezichtsbehandeling/masque-lumiere",
        title: "Masque Lumiere",
        description: "",
        content: `Door middel van lichtfrequenties die via het masker op de huid worden geprojecteerd volgt er een interactie met het biologische systeem van de huid, zogenaamde fotobiostimulatie.<br/>
    Door verschillende kleuren te gebruiken werkt dit voor verschillende soorten behandelingen.
    <p>
    Rood (650-700nm): Huidverjonging / huidverbetering <br/>
    Groen (525-550nm): Littekens, wondgenezing en hyperpigmentatie<br/>
    Blauw (430-450nm): Acne<br/>
    </p>
    <p>
    Optimale behandeling is een kuur van 10 behandelingen, 5 weken van 2 behandelingen per week.
    </p>
    <p><em>Complete behandeling inclusief masque lumière € 65,00</em></p>
     <p>Kuur van 10 behandelingen € 400,00 (eerste, zesde en tiende behandeling zijn complete behandelingen, overige zeven alleen het masque lumière)<br/></p>
    `
    });
    pages.push({
        url: "/gezichtsbehandeling/zuurstof",
        description: "Zuurstof behandeling in Zwolle, Ijsselmuiden door Beauty Balance",
        canonical: "https://www.tiana.nu/gezichtsbehandeling/zuurstof",
        title: "Zuurstof",
        content: `<p>Zuurstof is de bron van het leven van alle cellen. De huid is als grootste orgaan van ons lichaam een zuurstofgrootverbruiker.
    Zuurstof beschermt de menselijke cellen tegen vroegtijdige veroudering en degeneratie. Al vanaf ons dertigste levensjaar wordt de zuurstoftoevoer naar de huid duidelijk slechter en verliest dit orgaan in sommige gevallen 65% van het zuurstofgehalte. Een gering zuurstofgehalte belemmert de stofwisseling tussen de cellen en bevordert het verouderingsproces.</p>
     
    <p>Zuurstof is primair verantwoordelijk voor de gezondheid en het behoud van de celstructuur in het menselijk lichaam. Voldoende zuurstof met een goede kwaliteit zorgt ervoor dat zich collageen vormt en versterkt de elastinevezels van de huid. Zuurstof verbeterd de doorbloeding.</p>
     
    <p>Tijdens de zuurstofbehandeling wordt de huid voorzien van 94% zuivere zuurstof.
    Door middel van een zuurstofapparaat, welke zuurstof zuivert, wordt onder druk de zuurstof de huid in ' geblazen '
    Naast het zeer aangename gevoel van deze behandeling is de werking veelzijdig te noemen.</p>
    <p>
    De resultaten zijn o.a:<br/><br/>
    - geeft direct zichtbaarbaar meer volume en stevigheid.<br/>
    - geeft direct een betere huidteint.<br/>
    - geeft een verhoging van de opname van werkstoffen.<br/>
    - verbeterd de genezing van de huid.<br/>
    - bevordert het regeneratie vermogen van de huid.<br/>
    - verbetert alle celfuncties<br/></p>
    `
    });

    const browsHeader = {
        images: [{ media: "(min-width: 800px)", imageUrl: "/assets/images/hybrid-aibrush-brows-header.jpg", sourceType: "image/jpg" },
        { media: "(max-width: 799px)", imageUrl: "/assets/images/hybrid-aibrush-brows-header.jpg", sourceType: "image/jpg" }],
        defaultImageUrl: "/assets/images/hybrid-aibrush-brows-header.jpg",
        alt: "Hybride & Aibrush brows - mooie wenkbrouwen met airbrush"
    }
    pages.push({
        topImage: browsHeader,
        url: "/gezichtsbehandeling/hybride-airbrush-brows",
        description: "Airbrush brows behandeling in Kampen door Beauty Balance Tiana",
        canonical: "https://www.tiana.nu/gezichtsbehandeling/hybride-airbrush-brows",
        title: "Airbrush Brows",
        content: `<p><h2>Ontdek DE nieuwste trends in wenkbrauw-land; Hybrid Brows en Airbrush Brows</h2>
        Hybrid Brows en Hybrid Airbrush zijn samen een ware revolutie in langhoudende wenkbrauwverf.<br/></p>
        <p>Hybrid Brows heeft het beste van beide werelden. Het lijkt op traditionele wenkbrauwverf én henna.<br/>
        De techniek voor wenkbrauwen Hybrid Brows creëert een expressieve, rijke afdruk op de huid en de haren, waardoor je wenkbrauwen optisch voller en strakker lijken.<br/>
        Met langduriger resultaat op zowel de huid als de wenkbrauw haartjes. Hybrid Brows verf heeft een ammonia-vrije formule en dat voorkomt dat de wenkbrauw haren uitdrogen.
        <br/>Daardoor gaat deze behandeling voor de wenkbrauwen perfect samen met Browlamination.
        <p>Deze behandeling is ook ideaal als je twijfelt over Powderbrows. Het heeft eenzelfde soort effect, maar dan is het minder permanent.</p>
        <br/>
        <h3>De voordelen van Hybrid en Airbrush wenkbrauwen</h3>
        <ul>
        <li>- Hoog gepigmenteerde wenkbrauwverf van een Nederlands tóp merk voor een expressief, rijk en langdurig resultaat</li>
        <li>- Kleurt zowel de haartjes als de huid</li>
        <li>- Blijft gemiddeld tot 10 dagen op de huid en tot 6 weken op de haren</li>
        <li>- Geschikt voor het camoufleren van oneffenheden of gaten in de wenkbrauw</li>
        <li>- Airbrush: Aangebracht in dunne laagjes met de airbrush voor een perfecte, natuurlijke look. Strakke onderlijn van de wenkbrauwen en een mooie natuurlijke poederlook.</li>
        </ul>
        <bAirbrush Brows</b>
        <br/>
        <i>Uitmeten,<br/>
        Shapen: Epileren/waxen/bijknippen, verven met hybride verf<br/>
        – Nazorg en persoonlijk advies voor thuis.</i>
    `,
    prizes: {
        prices: [
            { name: `Uitmeten, <br/> Shapen: Epileren/waxen/bijknippen, verven met hybride verf<br/>
            – Nazorg en persoonlijk advies voor thuis.`, price: "€ 44,50", nameClasses: "inline-block full", priceClasses: "font-Primary text-2xl background-grey" }
        ]
    },
    });

    

    pages.push({
        url: "/visie-beauty-balance",
        description: "Visie van Beauty Balance in Kampen, Motet 24, Wie is Tiana?",
        canonical: "https://www.tiana.nu/visie-beauty-balance",
        title: "Visie Beauty Balance",
        content: `<p>Uw huid is uniek! Onze huid is een afspiegeling van onszelf.<br/>
    Daarom is het bij Beauty Balance het allerbelangrijkste dat geen enkele gezichtsbehandeling hetzelfde is.<br/>
    Iedere behandeling wordt afgestemd op uw persoonlijke wensen en wat uw huid nodig heeft. De uitdaging voor mij is om van elke behandeling een bijzondere fijne ervaring te maken, u bent aan het juiste adres voor een ontspannende en huidverbeterde behandeling.
    </p>
    <p> 
    Een verzorgingsadvies voor thuis is altijd mogelijk. U mag van mij een op uw huid afgestemd persoonlijk advies verwachten, maar ik zal nooit voorbij gaan aan uw eigen wensen. Het leveren van kwaliteit is voor mij van groot belang en ik heb de ambitie om te streven naar een maximaal resultaat.
     </p>
     <p>
    Door de samenwerking met een erkend huidtherapeute kan ik u altijd helpen om een oplossing te zoeken voor uw eventuele huidproblemen.
    </p>
    <p>
    Dit alles natuurlijk in een prettige omgeving zodat u ook heerlijk kunt ontspannen en genieten van de behandeling.
    </p>
    <p>
    Heeft u na het kijken en lezen van deze website nog vragen of wilt u een afspraak maken, dan kunt u mij altijd bellen of een mailtje sturen vanuit CONTACT
    </p>
    <p>
    Graag verwelkom ik u in mijn salon.
    </p>
    `
    });

    const athomeHeader = {
        images: [{ media: "(min-width: 800px)", imageUrl: "/assets/images/gezichtsbehandeling-voor-thuis.webp", sourceType: "image/webp" },
        { media: "(max-width: 799px)", imageUrl: "/assets/images/gezichtsbehandeling-voor-thuis.webp", sourceType: "image/webp" }],
        defaultImageUrl: "/assets/images/gezichtsbehandeling-voor-thuis.jpg",
        alt: "7 stappenplan gezichtsbehandeling voor thuis"
    }

    pages.push({
        topImage: athomeHeader,
        url: "/gezichtsbehandeling/voor-thuis",
        title: "Gezichtsbehandeling @ home",
        description: "Gezichtsbehandeling voor thuis a EUR 15,00, in 7 stappen naar schoonheid, schoonheidsspecialiste aan huis",
        content: `Een uitgebreide gezichtsbehandeling voor thuis van schoonheidsspecialiste Beauty Balance uit Kampen.<br/>
    Een 7 stappenplan begeleid u naar een complete gezichtsbehandeling aan de hand van de gebruiksaanwijzing.<br/>
    Een van de leuke producten die bij ons verkrijgbaar zijn als cadeau naast onze cadeaubon.<br/>
    <p>Geniet thuis van deze complete gezichtsbehandeling om je huid de tijd en aandacht te geven die het verdiend inclusief een uitgebreide gebruiksaanwijzing zodat het net voelt alsof je in de stoel van de schoonheidsspecialiste ligt.
    <br/></p><h2>Een beetje de schoonheidsspecialist bij je thuis.</h2><br/>
    <em>Mocht je nog vragen hebben naar aanleiding van de gebruiksaanwijzing neem dan gerust contact op.</em><br/><br/>
    <center><q class='m-8 md:m-40 font-Primary xs:text-2xl sm:text-3xl md:text-4xl text-secundary font-normal text-center'>Let your skin shine</q></center><img class="image-center -mt-2" height="22" width="50" src="/assets/images/infinite-icon.png" alt="oneindig mooi bij Beauty Balance" />
    <p><ol class="list-none md:list-decimal">
    <li class="p-4"><h3 class='text-xl'>Lait hydermios</h3><p>'s Morgens en 's avonds met vochtige vingers in de huid masseren en nadrogen met vochtige watten.
    Toelichting Lait Hydermios is een hydrofiele lait, dus ook te gebruiken met wat water. Ideaal voor die cliënten die eigenlijk water prefereren boven een lait.</p>
    <em>Lait Hydermios bevat verzachtende olie en andere natuurlijke ingrediënten, waaronder kamille, dat werkt als een tonicum.</em><br/>
    <a class="underline" href="/shop#Lait_Hydermios">Lait Hydermios is ook los te bestellen in onze shop >></a>
    </li>
<li class="p-4"><h3 class='text-xl'>Lait exfoliant</h3><p>Twee maal per week aanbrengen met vochtige vingers. Met roterende bewegingen scrubben.<br/>Bij dikkere huiden beginnen met dagelijks gebruik tot het gewenste resultaat bereikt is.
Toelichting Speciaal ontwikkeld door Medex voor diegenen die een grondige reiniging van de huid willen combineren met een milde scrub. Vuildeeltjes en make-up worden verwijderd en de gezichtshuid wordt optimaal op de volgende stap van uw verzorging voorbereid. De zachte silicakorrel zorgt voor een licht peelend effect, waardoor de dode huidcellen worden verwijderd. Door de milde, plantaardige oliën droogt de huid niet uit, dit maakt deze lait geschikt voor vele huidtypen: zowel normale en vochtarme huid, alsook de drogere en dikkere huid.
Is opgebouwd uit waardevolle componenten, zoals calendula officinalis, dat een kalmerende werking op de huid heeft, en vitamine E . Zo zorgt Lait Exfoliant voor het behoud van een zachte en soepele huid. Bevat geen parfum; de aangename geur is afkomstig van de natuurlijke etherische olie cananga odorata.
</li>
<li class="p-4"><h3 class='text-xl'>Enzymatische peeling</h3><p></p>peeling die zorgt voor een betere doorbloeding in de huid
Aanbrengen op de huid, 10 minuten laten intrekken en daarna afspoelen</p></li>
<li class="p-4"><h3 class='text-xl'>Actiprotin oogserum</h3><p>'s Morgens en/of 's avonds aanbrengen, na de gezichtsreiniging en het aanbrengen van de lotion. Geschikt voor dagelijks gebruik op de huid rondom de ogen.
Toelichting)De eerste tekenen van ontsierende verouderde huid manifesteren zich het eerst rond de ogen. Crème pour les Yeux bevat het krachtige preparaat “jeugdbevorderend” Tinticosyn, dat zorgt voor de aanmaak van het huideigen collageen en elastine, de proteïnen die zorgen voor stevigheid in de huid. Bevat daarnaast plantaardige ingrediënten zoals kamille. Door dagelijks deze kruidengel Crème pour les Yeux aan te brengen wordt ook al snel de vochtbalans hersteld en krijgen de ogen weer een jeugdig aanzien.
Tip: Speciale aanbeveling om Crème pour les Yeux samen met 'Actiprotin' in kuurverband te gebruiken. Voor het allerbeste resultaat de huid eerst goed reinigen met 'Lait Hydermios'. Daarboven elke vier weken een oogmasker met 'Avostem Eye Mask' geeft een extra ondersteuning om het allerbeste resultaat te verkrijgen
</p></li>
<li class="p-4"><h3 class='text-xl'>Face lift masker</h3><p>Na reiniging met Deep Clean in een dun laagje aanbrengen en ca. 20 minuten op het gelaat laten, daarna met veel lauw water verwijderen. ± 1 x per week gebruiken. Na het masker een dag- of nachtcrème aanbrengenzijn.
Toelichting Een zacht, romig masker dat de huid verstevigt en spant zonder stijf en hard te worden. In Face Lift Mask is 'jeugdbevorderend' Tinticosyn verwerkt, een revolutionaire vinding van Medex, die zorgt voor aanmaak van huidverstevigende proteïnen zoals collageen en elastine. Het masker bevordert zo het proces van regeneratie en werkt tevens aan de vochtbalans in de huid door een uniek algenextract. Alleen de allerbeste bestanddelen zijn gebruikt als basisemulsie, zo bevat het o.a. bijenwas. Het resultaat na gebruik van het masker is dan ook een zachte, beweeglijke en stevige huid.
</p></li>
<li class="p-4"><h3 class='text-xl'>Essence de beaute</h3><p>2x daags aanbrengen op een gereinigde huid onder de dag-of nachtcrème en licht inmasseren. Tevens te gebruiken voor lichaamsverzorging.<br/>
ToelichtingL ESSENCE DE BEAUTÉ biedt optimale hydratatie en huidverjonging door de pH-balans van de huid te herstellen en essentiële voeding te geven, o.a. door natuurlijke ingrediënten zoals Hyaluronzuur, Vitamine B5 en het essentiële element Magnesium.<br/>Bevat tevens het anti-ageing oligo-peptide “Acetyl Tetrapeptide-5” dat zorgt voor het behoud van de huidelasticiteit. Tevens te gebruiken voor lichaamsverzorging. Het Essence de Beauté is een hydraterend serum om je huid voor te bereiden op de crème die je daarna gebruikt. Een droge huid kan minder gemakkelijk een crème opnemen in vergelijking tot een enigszins vochtige huid, denk hierbij aan een spons.
Essence de Beauté” vormt de hydraterende laag die de huid op de juiste manier voorbereidt om maximaal effect en resultaat te hebben van de mooie serums (die u eventueel ook gebruikt) en de dag- en nachtcrème.
</p>
<a class="underline" href="/shop#Essence_de_Beaute">Essence de beaute is ook los te bestellen in onze shop >></a
</li>
<li class="p-4"><h3 class='text-xl'>Creme Riche de l' Ocean</h3><p>Iedere morgen en avond aanbrengen op de goed gereinigde huid.<br/>
Toelichting Crème Riche de l'Océan is een typische 24-uurscrème en heeft een heerlijk vol huidgevoel, geschikt voor tere, droge en vochtarme huid.
Algen en zeewieren hebben als geen ander te kampen met een enorme blootstelling aan uv-straling, wanneer de zon schijnt op de open oceanen. De beschermingsmechanismen van deze zeeorganismen zijn derhalve zéér geavanceerd. Het Medex-Laboratorium heeft uit de oceanen een anti-oxidatief trio samengesteld, o.a. op basis van algenstamcelextracten. Door dit te combineren met een Ecocert-emulgator en natuurlijke pro-vitamine A, die beide helpen de huidbarrière te herstellen van door uv-straling beschadigde huid, is een zeer krachtig anti-ageing product gerealiseerd.
</p></li>
</ol></p>
</div>
</section>`});

    const neeldingHeader = {
        images: [{ media: "(min-width: 800px)", imageUrl: "/assets/images/microneelding.webp", sourceType: "image/webp" },
        { media: "(max-width: 799px)", imageUrl: "/assets/images/microneelding-640.webp", sourceType: "image/webp" }],
        defaultImageUrl: "/assets/images/microneelding.jpg",
        alt: "Microneelding -"
    }
    pages.push({
        topImage: neeldingHeader,
        url: "/gezichtsbehandeling/microneedling",
        title: "Microneelding",
        description: "Microneelding  bij Beauty Balance Kampen",
        content: `<h2>Microneelding is een zachte techniek die het genezingsproces van de huid stimuleerd</h2>
    <p>Tijdens de behandeling wordt er met de medex microneedling over het gezicht gerold waardoor er hele kleine naaldjes in de huid wordt gestempeld.<br/>
    Hierdoor ontstaan er minuscule gaatjes in de huid, wat ervoor zorgt dat de aangebrachte werkstoffen optimaal worden opgenomen en goed kunnen doordringen in de huid.<br/>
    Wanneer u na een behandeling een serum aanbrengt, zal uw huid tot 300% meer van de actieve werkstoffen opnemen.<br/>
    De naaldjes stimuleren de doorbloeding en de celdeling.<br/>
    Na de behandeling herstelt de huid zich snel van de prikjes.<br/>
    Door deze stimulatie maken onze cellen collageen en elastische vezels aan.<br/>
    De toename van deze lichaamseigen stoffen zorgt voor een strakkere en elastischere huid en herstelt op deze wijze de beschadigde huid.<br/>
    De microneedling brengt bij veel huidproblemen verbetering.
    <h3>Microneedling – zorgt voor mooie resultaten bij</h3>
    <ul>
    <li>Littekens</li>
    <li>Een door zon beschadigde huid</li>
    <li>Grove porien of een grove huidstructuur</li>
    <li>Zwangerschapsmasker</li>
    <li>Rimpels en fijne lijntjes</li>
    <li>Pigmentvlekken</li>
    </ul>
    <center><em><strong>Let op: U heeft uw ‘eigen’ roller,<br/>
    Niemand anders wordt met ‘uw’’ roller behandeld</strong></em></center>
    <p><u><a href="/gezichtsbehandeling">Wij hebben een mooie behandeling voor u samengesteld voor EUR 75,00 >></a></u></p>
    `});

    pages.push({
        topImage: generalHeader,
        url: "/gezichtsbehandeling/beauty-angel-herbal-pealing-treatment",
        title: "De Beauty angel Herbal Peeling treatment",
        description: "De Beauty angel Herbal Peeling treatment bij Beauty Balance in Kampen",
        content: `<h2>Deze schoonheidsbehandeling is bij meerdere huidproblemen in te zetten.</h2>
        <p>Bij huidverbetering en huidverjonging en acne, eczeem en psoriasis<br/>
        <ul>
        <li>Het stimuleert bij wondgenezing</li>
<li>Stimuleert de doorbloeding</li>
<li>Optimaliseert de vochtbalans</li>
<li>Snel zichtbare regeneratie door verbetering van het celmetabolisme</li>
<li>Productie van collageen, elastine en hyaluron wordt verhoogd</li>
<li>Huidkwaliteit verbetert, diepte van rimpels verminderd aanzienlijk</li>
<li>Het huidvernieuwingsproces zal versneld plaats nemen</li>
<li>Talgproductie en andere huidfuncties normaliseren en stabiliseren</li>
<li>Dode huidcellen worden effectief verwijderd</li>
<li>Verlichten van pigment vlekken</li>
<li>De huid is en voelt grondig gereinigd</li>
<li>En het belangrijkste de huid wordt gereset</li>
        </ul>
        <h3>Huid verbetering en huidverjonging</h3>
        <p>activatie/ stimulatie fibroblasten silicium spicula – zorgt voor een milde en gecontroleerde ontstekingsreactie<br/>
        toevoeging van peptiden stimuleren – celvernieuwing  en aanmaak van collageen, elastine en hyaluronzuur
        </p>
        <h3>Acne, eczeem en psoriasis</h3>
        spongine uit de beauty angel herbal peeling behandeling remt de talgproductie dit draagt bij aan:<br/>
        <ul>
<li>vermindering van ontstekingen van de huid</li>
<li>vermindering van hyperkeratose</li>
<li>antiseptische eigenschappen. Stopt de acne bacterie</li>
<li>werkt van binnen naar buiten</li>
<li>verbeterde en snellere celopbouw van de huid en huidbarriere</li>
<li>verzacht de huid en verbetert de elasticiteit</li>
        </ul>

        <p>Deze behandelingen zijn ook in kuurverband te doen voor een nog meer optimale resultaat<br/><u><a href="/gezichtsbehandeling">Wij hebben een mooie behandeling voor u samengesteld voor EUR 89,50 >></a></u></p>
        `});

    pages.push({
        topImage: generalHeader,
        url: "/lash-volume-lift",
        title: "Lash volume lift",
        description: "Lash volume lift, wimpers",
        prizes: {
            prices: [
                { name: "Lash volume lift excl. verven", price: "€ 37,50", nameClasses: "inline-block full", priceClasses: "font-Primary text-2xl background-grey" },
                { name: "Lash volume lift incl. verven", price: "€ 47,50", nameClasses: "inline-block full", priceClasses: "font-Primary text-2xl background-grey" },
            ]
        },
        content: `<p>
            Iedereen droomt ervan om wimpers te hebben die er langer uit zien!<br/>
            Leg je wimperkrultang aan de kant! De Lash volume lift is een baanbrekende techniek in de beautybranche.<br/>Met deze 45 minuten durende professionele behandeling, heb je geweldige gelifte natuurlijke wimpers (zonder het aanbrengen van wimperextensions) met resultaten van wel 6 weken.
            De Lash Volume Lift behandeling van Perfect Eyelash is de prijswinnende behandeling van de Dutch Beauty Awards 2021</p>
            <h2>Wat houdt Lash Volume Lifting in?</h2>
            <p>De Perfect Eyelash Lash Volume Lifting is een kortdurende behandeling met een inwerktijd van maximaal 45 minuten Daarnaast is het voor klanten die geen wimperextensions dragen een zeer geliefde behandeling, omdat deze weinig onderhoud behoeft en de behandeling relatief kort duurt.<br/>Tevens heeft de behandeling een lang draagplezier.</p>
            <h3>Natuurlijke ingrediënten</h3>
            <p>Perfect Eyelash gaat voor duurzaamheid en natuurlijke materialen. Veel andere merken maken gebruik van chemische, voordeligere ingrediënten. Die zijn echter niet goed voor de natuurlijke wimperhaar en kunnen het haar ernstig beschadigen.
            Perfect Eyelash Lash Volume Lift gebruikt in lotion 1 keratine. Dit product bevat CYSTEAMINE HCI, een veel zachter ingrediënt dat vergelijkbaar is met de natuurlijke cyteamine/keratine van de wimperhaar. Dit helpt de structuur en elasticiteit van het haar te verbeteren.</p>
            <h2>Wat kunt u verwachten van de Lash volume Lift:</h2>
            <ul>
            <li>•	Het is een zeer comfortabele behandeling met de kortste inwerktijd wereldwijd op het gebied van wimperlifting</li>
            <li>•	Het laat natuurlijke wimpers langer lijken zonder wimperextensions.</li>
            <li>•	Perfect gekrulde, gelifte en gepositioneerde wimpers voor een natuurlijk resultaat.</li>
            <li>•	Gelifte wimpers tot wel 6 en mogelijk 8 weken.</li>
            <li>•	Minimale nazorg vereist! Zwemmen, douchen, sporten en make-up zijn geen probleem.</li>
            <li>•	Maakt uw wimperkrultang overbodig.</li>
            <li>•	Geschikt voor lange en korte wimpers, voor zowel mannen als vrouwen.</li>
            <li>•	Unieke formule die zorgt voor gezonde en gevoede wimpers. Niet schadelijk voor natuurlijke wimpers.</li>
            <li>•	Voelt natuurlijk aan (u kunt normaal in uw ogen wrijven)</li>
            <li>•	Geen kans op allergische reacties.</li>
            </ul><br/>
            <p>Deze unieke behandeling is geschikt voor elke wimpersoort en lengte. De behandeling kan zelfs bij korte wimpers toegepast worden en is bijzonder geschikt voor Aziatische vrouwen met rechte wimpers.<br/>Lash volume lift is niet schadelijk voor uw wimpers door de milde formule die dicht bij de Ph waarde komt van uw wimpers en geeft een volledige voeding vanaf de haarwortel.<br/>Een geweldig alternatief voor diegenen die geen wimperextensions willen, maar tocht een WOW-effect willen behalen. Voor een nog intenser en mooier resultaat, raden wij u aan om tijdens de behandeling uw wimpers prachtig zwart te laten verven met de speciaal hiervoor ontwikkelde wimperverf.
            <br/>Het effect blijft tot wel 6 weken zichtbaar!<br/></p>
            <p>
            <i>Voor een optimaal resultaat en behoud hiervan, adviseren wij de volgende richtlijnen in acht te nemen:</i><br/>
            Gedurende 48 uur geen sterke oogmake-up removers te gebruiken.<br/>
            Vermijd de eerste 24 uur na de behandeling douches of sauna's.<br/>De wimpers mogen op geen enkele manier nat worden, water/vocht kan de vloeistoffen deactiveren waardoor de wimpers gaan ontspannen en de krul uit zal zakken.<br/>Vermijd de eerste 24 uur het gebruik van shampoo en conditioner, make-up, crèmes of oogserums op de wimpers en/of oogleden.</p>
            `
    });

    pages.push({
        topImage: generalHeader,
        url: '/gezichtsbehandeling/beauty-angel-kruiden-peeling',
        title: 'Kruiden peeling Beauty Angel',
        description: 'Beauty Angeld Kruiden peeling',
        content: `<p>Bij deze behandeling wordt een kruidenpeeling aangebracht die bestaat uit een mix van zeewier en algen.
            De synergetische werking van deze peeling zet de huid aan tot herstel van binnenuit, met een zichtbaar snel resultaat.
            Deze werking stimuleert het lichaamseigen vermogen tot vernieuwing van binnenuit.</p>
            
            <p>De Beauty Kruidenpeeling is gebaseerd  op een combinatie van zeewier, zoetwaterspons en minerale klei.<br/>
            <ul>
                <li>Samengesteld uit natuurlijke ingredienten</li>
            <li>Heeft een krachtige werking op de fibroblasten van de huid</li>
            <li>Zorgt voor een milde ontstekingsreactie, stimuleert celvernieuwing</li>
            <li>Verbetert de huidkwaliteit</li>
            <li>Breed inzetbaar</li>
            </ul>
            <br>
            <p>Verschillende werkstoffen en daar de werkingen van<br/>
            Blaaswier: antioxidant, verbetert de elasticiteit , hydrateert en verzacht de huid.<br/>
            Roze alg: verhoogt vitaliteit, verbetert elasticiteit<br/>
            Montmorilloniet:  absorbeert overtollig talg, reinigt zorgvuldig en acne<br/>
            Witte spongilla: stimuleert de bloedsomloop, versoepelt littekens, herstelt beschadigde huid.<br/></p>
            
            <h2>De Beauty Angel kruidenpeeling behandeling is bij meerdere huidproblemen in te zetten</h2>
            <b>Huidverbetering en huidverjonging:</b><br/>
            -	Beginnende huidveroudering – fijne lijntjes<br/>
            -	Verouderde huid – rimpels en huidverslapping<br/>
            <i>Acne, eczeem en psoriasis</i><br/>
            -	Acne, grove porien, vette huid<br/>
            -	Verstoring in de afschilferinf<br/>
            <p>
            Aciveert/ stimuleert de fibroblast- zorgt voor een milde en gecontroleerde ontstekingsreactie
            Toevoeging van peptiden stimuleren- cel vernieuwing en aanmaak van collageen, elastine en hyaluronzuur.</p>
            <p>
            <b>Deze behandeling is geschikt voor:</b><br/>
            <ul>
            <li>Alle huidtypes vanaf 25 jaar</li>
            <li>Beginnende huidveroudering ( fijne lijntjes )</li>
            <li>Verouderde huid ( rimpels en huidverslapping )</li>
            <li>Acne, grove porien, vette en onzuivere huid.</li>
            <li>Acnelittekens, striae</li>
            <li>Grauwe huid, rokershuid</li>
            <li>Hyperpigmentatie</li>
            <li>Huid met roodheden</ul>
            <p>
            `
    });

    pages.push({
        topImage: generalHeader,
        url: '/gezichtsbehandeling/brow-lamination',
        title: 'Brow Lamination',
        description: 'Brow lamination',
        content: `<p>Groeien jouw wenkbrauwhaartjes alle kanten op, behalve de juiste?<br/>Dan is Brow Lamination dé behandeling voor jou! Brow Lamination vindt zijn oorsprong in Rusland, en is de allernieuwste trend op het gebied van browstyling.
<h3>Wat is Brow Lamination?</h3>
<p>Brow Lamination is een nieuwe behandeling waarbij jouw natuurlijke wenkbrauwhaartjes in de juiste richting geplaatst worden. Dit wordt gedaan met behulp van speciale lotion-formules. Hierdoor lijken je wenkbrauwen meteen veel voller. Het resultaat is heel natuurlijk en blijft ca. 6 weken zichtbaar. Bij deze behandeling is het modelleren van de wenkbrauwen (waxen, tweezen en trimmen) inclusief. Verven kan tegen meerprijs.</p>
<i>Voor wie is Brow Lamination geschikt?</i><br/>
Brow Lamination is geschikt voor iedereen met gekrulde, warrige of gewoon zeer eigenwijze wenkbrauwhaartjes. De behandeling is niet geschikt als je dunne, fijne, korte of heel erg weinig wenkbrauwhaartjes hebt.<br/>
<i>Is Brow Lamination schadelijk?</i><br/>
Nee, absoluut niet. We sluiten de behandeling altijd af met een intens voedend serum met daarin o.a. keratine, arganolie, castorolie en vitamine E. Je krijgt ook speciale nazorg mee voor thuis (inbegrepen).<br/>
<p>
<ul>
Contra-indicaties:<br/>
<li>Zwangerschap en/of borstvoeding</li>
<li>Acne en/of eczeem bij de wenkbrauwen</li>
<li>Huidbeschadigingen bij de wenkbrauwen</li>
<li>Bij recent geplaatste PMU/microblading</li>
<li>Bij recent gebruik van hormoonzalven bij de wenkbrauwen</li>
<li>Bij recente chemische peelingen, laserbehandelingen en/of injectables</li>
</ul>
</p>

<h3>Hoe vaak mag je een Brow Lift ondergaan?</h3>
Je mag de Brow Lift ondergaan eens in de twee maanden.<br/>
<p>
<ul>
Na de behandeling (aftercare)<br/>
<li>Maak de wenkbrauwen niet nat de eerste 24 uur na de behandeling</li>
<li>Ga niet in de sauna of zwemmen de eerste 24 uur na de behandeling</li>
<li>Ga niet sporten (ivm transpireren) de eerste 24 uur na de behandeling</li>
<li>Gebruik geen wenkbrauw styling producten de eerste 24 uur na de behandeling</li>
<li>Niet aan de wenkbrauwharen zitten</li>
<li>Kam de haren niet tegen de groei in!</li>
<li>Geen gezichtsbehandelingen de eerste 24 uur na de behandeling</li>
<ul>
<br/>
Duur: 30-60 min
<p>`,
        prizes: {
            prices: [
                { name: "inclusief modelleren", price: "42,50" },
                { name: "inclusief modelleren + verven", price: "52,50" },
            ]
        }
    });

    const fibroblastingHeader = {
        images: [{ media: "(min-width: 800px)", imageUrl: "/assets/images/fibro-blasting-header.webp", sourceType: "image/webp" },
        { media: "(max-width: 799px)", imageUrl: "/assets/images/fibro-blasting-300.webp", sourceType: "image/webp" }],
        defaultImageUrl: "/assets/images/fibro-blasting-header.jpg",
        alt: "Microneedling"
    }
    pages.push({
        topImage: fibroblastingHeader,
        url: '/fibro-blasting',
        title: 'Fibroblasting',
        description: 'Fibro blasting',
        content: `<p>Bij Beauty Balance, schoonheidsspecialiste in Kampen, Fibroblasting.
            Deze behandeling wordt ook wel plasma pen behandeling of plexr behandeling genoemd. Is te boeken in onze schoonheidssalon in Kampen.
            </p>De fibroblast-behandeling met de plasma-pen is een nieuwe non-invasieve (dit betekend zonder snijden, hechten, narcose en ontsierende littekens) revolutionaire behandeling uit Italië. De Fibroblast plasma-pen produceert aan het uiteinde van de fibroblast-pen een klein plasmatisch ‘flitsje’. Dit ‘flitsje’ dringt vervolgens door in de huid. Dit werkt op de fibroblasten waardoor de huid direct krimpt en zorgt  voor een verstevigend effect en voor een snelle en littekenloze genezing.<p>
            <p>Doordat de opperhuid als het ware ‘verdampt,  ontstaat er een klein bruin stipje, een zogenaamde thermische reactie.  Deze stipjes worden, afhankelijk van het te behandelen gebied, strategisch geplaatst waardoor de huid direct zichtbaar krimpt en de overtollige huid wordt gelift. De pen raakt de huid dus niet aan en de huid blijft gesloten. Er is dus geen open wond, wat het risico vermindert op bijwerkingen zoals, infecties en littekens. Het genezingsproces is dan ook aanzienlijk korter dan bij een traditionele ooglifting.
            De belangrijkste functie van fibroblasten in de huid is de aanmaak van collageen. Naarmate je ouder wordt neemt niet alleen het aantal fibroblasten af, maar worden ze ook minder actief. Simpel gezegd betekent dit: het ondersteunende weefsel wordt minder stevig, met huid verslapping als gevolg. De fibroblasten bevinden zich in de 2e huidlaag, ook wel lederhuid genoemd.
            Plasma wat via de plasma pen de huid in dringt is een dominante gasvormige stof. Plasma bestaat uit geïoniseerde deeltjes (atomen, moleculen, elektronen) die elektrisch positief en negatief geladen zijn. Dit is een natuurkundig proces en kan op verschillende manieren bij huid- en verouderingsproblemen toegepast worden.
            Hoe lang het resultaat zichtbaar blijft hangt helemaal van de huid en de mimiek af. Rimpels door mimiek (frons/voorhoofd) zullen eerder weer zichtbaar zijn, dan rimpels door huidverslapping. Het effect is langdurig. Maar omdat de behandeling pas een paar jaar bestaat, is nog lastig te vermelden hoelang langdurig is.  Maar na 1 behandeling is het resultaat al zeer goed zichtbaar. Per behandeling kan de huid een paar millimeter krimpen en dus verstrakken. Daarnaast heeft de behandeling ook nog een doorwerkend effect. Omdat de fibroblasten geactiveerd zijn en dus ook weer collageen aanmaken, werkt dit nog 6-8 weken door in de huid en is het optimale resultaat dan ook pas zichtbaar. 
            <br/>Bij extreem overhangende huid bij de ogen zal, ofwel meer behandelingen nodig zijn, ofwel door verwezen worden naar een plastisch chirurg voor de traditionele ooglid-lifting. Dit wordt vooraf in de intake besproken.</p>
            <h2>Behandelmogelijkheden</h2>
            <p>De behandeling werkt het beste bij huidverslapping. Met name zone rond en onder de ogen en de wangen zijn zeer goed te behandelen met een duidelijk resultaat al na de eerste behandeling. Daarnaast kun je rimpels, kraaienpootjes, lichte huidplooien en overtollige en verslapte huid op de verschillende zones van het gehele lichaam te behandelen. Zelfs een halslifting is mogelijk. Maar ook het gebied rond de navel (na zwangerschap). De behandeling is ook zeer effectief voor pigment-/ouderdomsvlekken. Direct na de behandeling ziet u al resultaat. De huid is lichtroze en de volgende dag ontstaan hier korstjes op. Deze vallen er binnen een week af en daaronder bevindt zich de nieuwe huid. In verband met kans op hyperpigmentatie moeten deze plekjes wel goed beschermd worden met een hoge beschermingsfactor (50) tegen UV straling. Indien vlekjes onrustig zijn of aan verandering onderhevig, dan dient eerst overleg en goedkeuring met de dermatoloog plaats te vinden.</p>
            <i>De volgende gebieden cq resultaten kun je naast de liftende werking van de plasma pen ook bereiken.</i>
            <ul>
            <li>Non actieve acne verbetering/verwijdering</li>
            <li>Pigmentverwijdering/vermindering</li>
            <li>Verwijdering/verbetering van zwangerschapsstriemen</li>
            <li>Verwijdering/verbetering van striae</li>
            <li>Verwijdering/verbetering van couperose</li>
            <li>Verwijdering/verbetering van littekens</li>
            <li>Verwijdering/verbetering oppervlakkige tot diepe rimpels</li> 
            <li>Verwijdering van wratten en fibromen (wild vlees)</li>
            </ul>
            <h3>Contra indicaties:</h3>
            <p>Indien een van onderstaande situatie(s) voor u van toepassing is, wordt de behandeling afgeraden of vindt in overleg (eventueel met arts) plaats:</p>
            <ul>
            <li>Pacemaker</li>
            <li>Zwangerschap</li>
            <li>Diabetes (tenzij het onder controle is)</li>
            <li>Fillers (pas na een half jaar)</li>
            <li>Sterke keloïdvorming bij genezing ( littekenweefsel)</li>
            <li>Koorts</li>
            <li>Huidkanker (altijd in overleg met behandelend arts)</li>
            <li>Gebruik van bloedverdunners altijd vooraf melden.</li>
            <li>Actieve herpes ( indien niet meer actief wel eventueel met medicatie voor de behandeling beginnen)</li>
            <li>Actieve acne</li>
            <li>Antibiotica</li>
            <li>Alcoholgebruik de avond voor de behandeling</li>
            <li>Ontstekingen</li>
            <li>Gebruik van vitamine A, St.Janskruid (lichtgevoelig) en zware medicijnen of cremes die de huid gevoelig maken</li>
            <li>Allergie voor producten die gebruikt worden tijdens de behandeling, b.v. latex, emla creme</li>
            </ul>
            <p><br/><strong class="font-bold">De voordelen van deze behandelmethode ten opzichte van Plastische of Cosmetische Chirurgie:</strong></p>
            <ul>
            <li>Geen operatie en ook geen narcose</li>
            <li>Bloed- en lymfevaten worden niet doorgesneden en blijven dus in tact wat de genezing bevordert. Huid blijft gesloten en dus geen hechtingen</li>
            <li>Snelle genezing (binnen 10 dagen)</li>
            <li>Geen verandering van de stand en uitdrukking bij ooglid-lifting</li>
            <li>Geen littekens en geen bloeduitstortingen</li>
            <li>Direct zichtbaar resultaat</li>
            <li>Herhaling behandelingen mogelijk</li>
            <li>Alle leeftijden en huidtypen voor zowel mannen als vrouwen</li>
            <li>Geen lichaamsvreemde stoffen</li>
            <li>Vrijwel risicoloos</li>
            <li>Perfecte vervanging voor fillers en botox. Geen kans op verklevingen, opgespoten lippen, hangende oogleden door verkeerd gespoten botox etc…</li>
            </ul>
            <br/>
            <h2>De Behandeling:</h2>
            <i>De behandeling bestaat uit verschillende onderdelen:</i><br/>
            <strong class="font-bold">Intakegesprek (gratis):</strong><br/>
            Uitgebreide informatie en doornemen van vragenlijst. Mogelijkheden en wensen van de klant worden besproken. Ook aan de hand van foto’s wordt de klant vooraf duidelijk geïnformeerd over het verloop van de behandeling en de nazorg. Indien de klant besluit tot behandeling over te gaan, wordt ofwel direct behandeld, ofwel een afspraak gemaakt voor de behandeling. Vooraf worden er duidelijke foto’s genomen van de te behandelen zone(s).<br/>
            <strong class="font-bold">De behandeling zelf:</strong><br/>
            Nadat het behandelde deel 30 minuten is verdoofd d.m.v. een verdovende creme zal de behandeling beginnen. Afhankelijk van de te behandelen zone en de huid zal de behandeling zelf ongeveer 30/40 minuten in beslag nemen.<br/>
            <h3>De behandeling zelf:</h3>
            <p>
Omdat de behandeling zonder verdoving als ongemakkelijk en vervelend ervaren kan worden, wordt er 30 minuten vooraf een verdovende creme aangebracht en afgedekt. Een pijnstiller (geen aspirine) vooraf innemen kan ook altijd helpen. Na deze verdoving is de behandeling heel goed dragelijk.<br/>
Zoals eerder besproken worden de ‘stipjes’ strategisch aangebracht op de te behandelen zone. Dit zijn direct bruine ‘stipjes‘ (de thermische werking), die een gestippeld geheel tonen. Na de behandeling kan er wat zwelling ontstaan, maar je ziet al direct het liftende effect. De bruine stipjes worden korstjes en laten na een dag of 5 al los. Dit proces duurt gemiddeld (afhankelijk van zone en huid) tussen de 5 en 10 dagen.<br/>
De zwelling die doorgaans ontstaat, zeker na de 2e dag, kan een paar dagen aanhouden, zeker in de ochtend en de zwelling zakt naar beneden. Heb je al last van een vochtprobleem rond je ogen dan kan dit wat langer duren.</p>
            `,
        prizes: {
            prices: [
                { name: "Ooglidcorrectie boven", price: "€249,-" },
                { name: "Ooglidcorrectie onder", price: "€249,-" },
                { name: "Ooglidcorrectie boven en onder", price: "€399,-" },
                { name: "Kraaienpootjes", price: "€199,-" },
                { name: "Ooglidcorrectie boven of onder en kraaienpootjes", price: "€349,-" },
                { name: "Ooglidcorrectie boven en onder en kraaienpootjes", price: "€599,-" },
                { name: "Fronsrimpel", price: "€149,-" },
                { name: "Voorhoofd", price: "€299,-" },
                { name: "Liplift (opstaand randje bovenlip)", price: "€149,-" },
                { name: "Mond/rokers rimpels bovenlip", price: "€199,-" },
                { name: "Afhangende mondhoeken", price: "€249,-" },
                { name: "Facelift kaaklijn", price: "€349,-" },
                { name: "Oor plooi", price: "€249,-" },
                { name: "Facelift kaaklijn en oor plooi", price: "€499,-" },
                { name: "Neus lippen plooi", price: "€199,-" },
                { name: "Wenkbrauw lift", price: "€199,-" },
                { name: "Decolleté rimpels", price: "€450,-" },
                { name: "Litteken verbetering", price: "v.a. €50,-" },
                { name: "Couperose verwijderen", price: "v.a. €50,-" },
                { name: "Steel wratten/fibromen", price: "v.a. €25,-" },
                { name: "Striae verbetering", price: "v.a. €100,-" },
                { name: "Permanente make-up verwijderen", price: "v.a. €150,-" },
            ],
            remark: "Voor behandelingen waar vanaf prijzen bijstaan, kan de prijs slechts bij de intake bepaald worden naar aanleiding van de grootte en de staat van het te behandelen gebied."

        }
    });

    return pages;
}

export interface LandingPageData extends LandingPageProps {
}
export default getLandingPages;