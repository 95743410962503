import React from "react";
import PriceListItem, { PriceListItemProps } from "./PriceListItem";

export interface PriceListProps {
  prices: PriceListItemProps[];
  pricesClasses?: string;
  remark?: string;
}

const PriceList = (props: PriceListProps) => {
  return (
    <>
    <ul className={props.pricesClasses || ""}>
      {props.prices.map((a: PriceListItemProps) => (
        <li key={a.name} className="relative">
          <PriceListItem {...a}></PriceListItem>
        </li>
      ))}
    </ul>
    {props.remark && <p><i>{props.remark}</i></p>} 
    </>
  );
};

export default PriceList;
