import React from 'react';

export interface ProductCardProps
{
    title: string;
    description: string;
    duration?:string;
    price: string;
    parts?: string[]
    cardClasses?:string;
    titleclassName?: string;
}

const ProductCard:  React.FC<ProductCardProps>  = (props: ProductCardProps) => {
    return <div className={`${props.cardClasses || '' } relative shadow-lg bg-white rounded-lg p-6`}>
            <h2 className="font-Primary xs:text-2xl sm:text-3xl text-secundary font-normal leading-3">{props.title}</h2>
            {props.description && <em className="text-xs" dangerouslySetInnerHTML={{ __html: props.description }}></em> }
            {props.parts && <ul>{props.parts.map((a, index) => <li key={index}>{a}</li> )}</ul>}
            <div className="absolute right-0 bottom-0 mr-2 mb-2 font-Primary text-2xl md:text-3xl text-purple">{props.duration ? `${props.duration} - `: ''} {props.price}</div>
        </div>;
}

export default ProductCard;