import React, { useState } from "react";
import Address from "../Components/Address";
import getAddress from "../Services/addressService";
import Titleblock from "../Components/Titleblock";
import Link from "../Components/Link";
import ContactForm from "../Components/ContactForm";
import { sendMail } from "../Services/mailService";
import TitleTag from "../Components/TitleTag";
import { useLocation } from "react-router-dom";

export interface ContactPageProps {
  name?: string;
  email?: string;
  remark?: string;
  phone?: string;
}

const ContactPage: React.FC<ContactPageProps> = (props: ContactPageProps) => {
  let name = "";
  let email = "";
  let remark = "";
  let phone = "";
  const location = useLocation();
  if (location && location.state) {
    const contactPageProps = location.state as ContactPageProps;
    name = contactPageProps?.name ?? "";
    email = contactPageProps?.email ?? "";
    remark =contactPageProps?.remark ?? "";
    phone = contactPageProps?.phone ?? "";
  }
  const [contactForm, setContactForm] = useState({
    name: name,
    email: email,
    phone: phone,
    remark: remark,
  });

  const handleInputChange = (name: string, value: string) => {
    const updatedContact = { ...contactForm, [name]: [value] };
    setContactForm(updatedContact);
  };
  
  const submitContactForm = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (
      contactForm.name == null ||
      contactForm.name === "" ||
      contactForm.name === "undefined" ||
      !contactForm.name ||
      contactForm.name[0] === "" ||
      contactForm.email == null ||
      contactForm.email === "" ||
      contactForm.email === "undefined" ||
      !contactForm.email ||
      contactForm.email[0] === "" ||
      contactForm.remark == null ||
      contactForm.remark === "" ||
      contactForm.remark === "undefined" ||
      !contactForm.remark ||
      contactForm.remark[0] === ""
    ) {
      return;
    } else {
      sendMail({
        content: {
          subject: "reactie vanaf website",
          bodyHtml: `Vraag vanaf website:<br/>"${contactForm.remark}<br/>Van:${contactForm.name}<br/>Email:${contactForm.email}<br/>Telefoon:${contactForm.phone}`,
        },
        from: { name: "info@tiana.nu", address: "info@tiana.nu" },
        to: [{ name: "info@tiana.nu", address: "info@tiana.nu" }],
      });
      setContactForm({ name: "", email: "", remark: "", phone: "" });
    }
  };

  return (
    <>
      <TitleTag
        title="Contact met Beauty Balance"
        date="2022-03-14"
        description="Bel 06 - 410 92 602 voor een afspraak, contact leggen met Tiana van Beauty Balance, salon gevestigd aan Motet 24 Kampen"
        canonical="https://www.tiana.nu/contact"
      />
      <section className="container ml-auto mr-auto lg:p-4">
        <div className="lg:flex lg:flex-row-reverse">
          <div className="lg:w-2/3">
            <ContactForm title="Bericht sturen" confirmationTitle="Verstuur" remarkField="Bericht"
              {...contactForm}
              onSubmit={submitContactForm}
              onInputChange={handleInputChange}
            ></ContactForm>
          </div>
          <div className="lg:w-1/3 text-darkgrey ml-8 lg:pt-20">
            <Titleblock titleLevel="h5" title="Adres:"></Titleblock>
            <Address {...getAddress()}></Address>
            <Titleblock titleLevel="h5" title="Telefoon:"></Titleblock>
            <div>
              <a href="tel:0641092602">06 - 410 92 602</a>
            </div>
            <Titleblock titleLevel="h5" title="Email:"></Titleblock>
            <Link
              name="info@tiana.nu"
              title="email naar tiana"
              url={`mailto:info@tiana.nu`}
            ></Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactPage;
