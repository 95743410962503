import React from 'react';
import { ProductCatalogItemProps } from './ProductCatalogItem';

interface ProductCatalogMetaDataProps
{
    products: ProductCatalogItemProps[] | null;
}

const ProductCatalogMetaData : React.FC<ProductCatalogMetaDataProps> = (props: ProductCatalogMetaDataProps) => {
    
    return ( 
        <>
        {props.products && <script
        key=''
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(createListSchema(props.products)) }}
    />}</>)
}

export default ProductCatalogMetaData;

function createListSchema(products: ProductCatalogItemProps[]): any {



    return {
        // schema truncated for brevity
        '@context': 'http://schema.org',
        '@type': 'ItemList',
        name:'Beauty Balance shop',
        url: 'https://www.tiana.nu/shop',
        numberOfItems : products.length,
        itemListElement: products.map(x=>createProductSchema(x))
    }
}
function createProductSchema(x: ProductCatalogItemProps): any {
    return (
        {
        "@type": "ListItem",
        position: x.id,
        "item": {
            "@type": "Product",
            gtin8: x.id,
            productID: x.id,
            image: `https://www.tiana.nu${x.picture.defaultImageUrl}`,
            name: x.subTitle,
            url: `https://www.tiana.nu/shop/${x.tagName}`,
            brand:  {"@type": "Brand", "name": "Medex"},
            description: `${x.title} ${x.subTitle}`,
            sku: x.id,
            offers: {
                "@type": "Offer",
                url: `https://www.tiana.nu/shop/${x.tagName}`,
                price: x.price,
                priceCurrency: "EUR",
                priceValidUntil: "2022-12-31",
                availability: "https://schema.org/InStock",
            },
            aggregateRating: {
                "@type": "AggregateRating",
                ratingCount: "1",
                ratingValue: "4",
                reviewCount: "1"
              },
        }
    });
}

