import React from 'react';
import Titleblock from '../Components/Titleblock';
export interface BedanktPageProps
{
    
}

const BedanktPage : React.FC<BedanktPageProps> = (props: BedanktPageProps) => {
   
    return ( 
        <section className="container ml-auto mr-auto lg:p-4">
            
            <Titleblock titleLevel="h1" title="Bedankt voor uw bestelling"></Titleblock>
            <div className="p-2">
              Wij zullen contact met u opnemen wanneer de producten klaar staan om een afspraak van dag en tijd.<br/>
              Met vriendelijke groeten,<br/>
              Tiana Dopmeijer, Beauty Balance
              </div>
        </section>
        )
}

export default BedanktPage;


