import React, { useEffect, useState } from "react";
import Picture from "../Components/Picture/Picture";
import ProductCard, { ProductCardProps } from "../Components/ProductCard";
import { getBehandelingenProducts } from "../Services/productCardService";
import TitleTag from "../Components/TitleTag";

interface BehandelingenPageProps {}

const massageHeader = {
  images: [
    {
      media: "(max-width: 640px)",
      imageUrl: "/assets/images/massages-in-kampen.webp",
      sourceType: "image/webp",
    },
    {
      media: "(min-width: 800px)",
      imageUrl: "/assets/images/massages-beauty-balance-header.webp",
      sourceType: "image/webp",
    },
  ],
  defaultImageUrl: "/assets/images/massages-beauty-balance-header.jpg",
  width: "100%",
  height: "100%",
  alt: "Massages bij Beauty Balance vanaf 22,50",
};


const BehandelingenPage = (props: BehandelingenPageProps) => {
  const [behandelingen, setBehandelingen] = useState<ProductCardProps[] | null>(null);
  useEffect(() => {
     getBehandelingenProducts().then((x) => {
      setBehandelingen(x);
  });},[]
  );
  return (
    <>
      <TitleTag
        title="Gezichtsbehandelingen overzicht"
        canonical="https://www.tiana.nu/gezichtsbehandeling"
        description="Alle gezichtsbehandelingen van schoonheidspecialiste Tiana in Kampen op een rij bij Beauty balance, schoonheidssalon is gevestigd Motet 24 Kampen"
      />
      <div className="w-full relative">
        <Picture
          imageClass="w-full"
          width="100%"
          height="100%"
          defaultImageUrl={massageHeader.defaultImageUrl}
          alt="IPL Beauty Balance Kampen"
          images={massageHeader.images}
        ></Picture>
        <div className="absolute bottom-0 left-0 flex p-4 bg-lightviolet w-full">
          <h1 className="text-4xl pt-4 sm:text-3xl md:text-5xl m-2 font-Primary text-white font-normal">
            Gezichtsbehandelingen
          </h1>
        </div>
      </div>
      <section className="bg-grey">
        <div className="flex-container justify-center">
          {behandelingen && behandelingen.map((a: ProductCardProps) => (
              <ProductCard
                key={a.title}
                {...a}
                titleclassName="md:text-3xl"
                cardClasses="w-full lg:w-1/3 m-2 md:m-6 "
              ></ProductCard>
          ))
          }
        </div>
      </section>
    </>
  );
};

export default BehandelingenPage;
