import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductCatalog, {
  ProductCatalogProps,
} from "../Components/ProductCatalog";
import { ProductCatalogItemProps } from "../Components/ProductCatalogItem";
import ProductCatalogMetaData from "../Components/ProductCatalogMetaData";
import TitleTag from "../Components/TitleTag";
import { getProductCatalogItems } from "../Services/productCatalogService";

interface ShopPageProps {
  addProductToCart(item: ProductCatalogItemProps): void;
  removeProductFromCart(item: ProductCatalogItemProps): void;
  title: string;
}
type ParamTypes = {
  productName: string
}

const ShopPage: React.FC<ShopPageProps> = (props: ShopPageProps) => {
  let param = useParams<ParamTypes>();
  const [pageTitle, setPageTitle] = useState<string>(props.title);
  const [products, setProducts] = useState<ProductCatalogProps>({
    title: "Producten",
    catalog: null,
    showInfo:true
  });
  useEffect(() => {
    getProductCatalogItems().then((x) => {
      let products = x.map((y) => {
        return {
          ...y,
          addToCart: props.addProductToCart,
          removeFromCart: props.removeProductFromCart,
        };
      });
      if (param && param.productName){
        let prodCatalog: ProductCatalogProps = {
          catalog:  products.filter(x=>x.tagName === param.productName),
          title: products[0].subTitle ? products[0].subTitle: "Producten",
          showInfo:true
        }
        setPageTitle(prodCatalog.title);
        setProducts(prodCatalog)
      }else{
        setProducts({ title: props.title, catalog: products, showInfo:true });
      }
      let index = document.location.href.indexOf("#");
      if (index > -1) {
        const marker = document.location.href.substring(index);
        const re = /#/gi;
        const idvalue = marker.replace(re, "");
        if (products != null) {
          const product = products.find((x) => x.tagName === idvalue);
          if (product != null) {
            let element = document.getElementById(product.id);
            let topposition = element?.getBoundingClientRect()?.top;
            window.scroll({
              top: topposition,
            });
          }
        }
      }
    });
  }, [props.addProductToCart, props.removeProductFromCart]);

  return (
    <section className="bg-grey p-2">
      <TitleTag
        title={pageTitle}
        description="Medex schoonheidsproducten kopen bij Beauty balance Kampen"
        date="2021-05-31"
        canonical="https://www.tiana.nu/shop"
      />
      {products && <ProductCatalog {...products}></ProductCatalog>}
      {products && <ProductCatalogMetaData products ={products.catalog}></ProductCatalogMetaData>}
    </section>
  );
};

export default ShopPage;
