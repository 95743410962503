import React from "react";

export interface TitleblockProps {
  titleLevel: TitleLevel;
  title: string;
  className?: string | null;
}

export type TitleLevel = "paragraph" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const Titleblock : React.FC<TitleblockProps> = (props) => {
  if (props.titleLevel === "paragraph") {
    return <p className={`${props.className || '' }`}>{props.title}</p>;
  }
  if (props.titleLevel === "h1") {
    return <h1 className={`${props.className || '' } xs:text-2xl pt-10 sm:text-3xl md:text-5xl m-2 font-Primary text-primary font-normal` }>{props.title}</h1>;
  }
  if (props.titleLevel === "h2") {
    return <h2 className={`${props.className || '' }`}>{props.title}</h2>;
  }
  if (props.titleLevel === "h3") {
    return <h3 className={`${props.className || '' }`}>{props.title}</h3>;
  }
  if (props.titleLevel === "h4") {
    return <h4 className={`xl:text-xl uppercase ${props.className || '' }`} dangerouslySetInnerHTML={{ __html: props.title }}></h4>;
  }
  if (props.titleLevel === "h5") {
    return <h5 className={`block uppercase text-black ${props.className || '' }`} dangerouslySetInnerHTML={{ __html: props.title }}></h5>;
  }
return (<h1>{props.title}</h1>);
};

export default Titleblock;
