import React, { ChangeEvent, useEffect, useState, lazy, Suspense } from "react";
import CardOverlay, { CardOverlayProps } from "../Components/CardOverlay";
import Slogan from "../Components/Slogan";
import {ImageTextBlockProps} from '../Components/ImageTextBlockProps';
import Card, { CardProps } from "../Components/Card";
import overTiana from "../Services/overTianaService";
import {Aanbieding, aanbiedingVanDeMaandItems, getNieuws,getUitgelichteBehandeling,Nieuws} from "../Services/aanbiedingVanDeMaandService";
import productCategoryItems from "../Services/productCategoryService";
import TitleTag from "../Components/TitleTag";
import { addNewsLetter } from "../Services/mailService";
import { ParallaxProps } from "../Components/Parallax/ParallaxProps";
import { Link } from "react-router-dom";
import Parallax from "../Components/Parallax/Parallax";
import ImageTextOverlayBlock from "../Components/ImageTextOverlayBlock";
import mainProductCategoryItems from "../Services/mainProductCategoryService";
interface HomePageProps {}


const TitleblockComponent = lazy(() => import("../Components/Titleblock"));
const TextBlockComponent = lazy(() => import("../Components/TextBlock"));
const renderLoader = <p>Loading</p>;
const parallaxImages = (): ParallaxProps => {
  const picture: ParallaxProps = {
    images: [
      {
        media: "(min-width: 800px)",
        imageUrl: "/assets/images/beauty-balance-welkom-800.webp",
        sourceType: "image/webp",
        width: "100%",
        height:"100%"
      },
      {
        media: "(min-width: 800px) and (min-resolution:.001dpcm)",
        imageUrl: "/assets/images/beauty-balance-welkom-800.jpg",
        sourceType: "image/jpeg",
      },
      {
        media: "(max-width: 420px)",
        imageUrl: "/assets/images/beauty-balance-welkom-300.webp",
        width: "420",
        height: "525",
        sourceType: "image/webp",
        
      },
      {
        media: "(max-width: 640px)",
        imageUrl: "/assets/images/beauty-balance-welkom-300.webp",
        sourceType: "image/webp",
      },
      {
        media: "(max-width: 1080px) and (aspect-ratio: 19/9)",
        imageUrl: "/assets/images/beauty-balance-welkom-333.webp",
        sourceType: "image/webp",
      },
    ],
    defaultImageUrl: "/assets/images/beauty-balance-welkom-800.jpg",
    alt: "Beauty balance welkom",
    width: "300",
    height:"539",
    lazy:false
  };
  return picture;
};

const HomePage = (props: HomePageProps) => {
  const [aanbiedingen, setAanbiedingen] = useState<CardProps[] | null>(null);
  const [nieuws, setNieuws] = useState<Nieuws | null>(null);
  const [error, setError] = useState<any>(null);
  const [about, setAbout] = useState<ImageTextBlockProps | null>(null);
  const [productCategories, setProductCategories] = useState<CardOverlayProps[] | null>(null);
  const [mainProductCategories, setMainProductCategories] = useState<CardOverlayProps[] | null>(null);
  const [headerImage, setHeaderImage] = useState<ParallaxProps | null>(null);
  const [uitgelichteBehandeling, setUitgelichtBehandeling] = useState<Aanbieding | null>(null);

  const [newsLetterForm, setnewsLetterForm] = useState({
    email: "",
    sent: false,
  });
  
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedContact = { ...newsLetterForm, email: event.target.value };
    setnewsLetterForm(updatedContact);
  };
  const submitNewsletterForm = (
    event: React.FormEvent<HTMLFormElement>
  ): void => {
    event.preventDefault();
    if (
        newsLetterForm.email == null ||
        newsLetterForm.email === "" ||
        newsLetterForm.email === "undefined")
      return;

    addNewsLetter(newsLetterForm.email).then( x=>{
    setnewsLetterForm({
      email: "",
      sent: true
    });
  }, (reason) => {
        setnewsLetterForm({
          email: "",
          sent: false
        });
      });
  };



  useEffect(() => {
    scrollToElement("aanbieding");
    setHeaderImage({...parallaxImages(), children : renderParallaxChilds()});
    aanbiedingVanDeMaandItems().then((x) => {
      setAanbiedingen(x);
    });
    getNieuws()
      .then((x) => setNieuws(x))
      .catch((e) => setError(e));
    setAbout(overTiana());
    setProductCategories(productCategoryItems());
    setMainProductCategories(mainProductCategoryItems());
    getUitgelichteBehandeling().then(
      (x) => setUitgelichtBehandeling(x)
    );
  }, []);

  if (error) throw error;
  
  return (
    <>
      <TitleTag
        title="Welkom bij Beauty Balance - Schoonheidssalon in Kampen"
        description="Schoonheidssalon Beauty Balance in Kampen, schoonheidsspecialiste voor IPL, huidverzorging, epileren en massage"
        canonical="https://www.tiana.nu"
        date="2022-04-16"
      />
      
        {renderHeaderImages(headerImage)}

        <Suspense fallback={renderLoader}>
        {renderProductCategories(mainProductCategories)}
      </Suspense>
      
      {rendederAdditional()}

      <Suspense fallback={renderLoader}>
        {renderProductCategories(productCategories)}
      </Suspense>
      
      <Suspense fallback={renderLoader}>
        {renderFeaturedTreatment(uitgelichteBehandeling)}
      </Suspense>

      {renderAbout(about)}

     
     
      
    </>
  );
};

const renderHeaderImages = (parallaxImage: ParallaxProps| null) => {
  return (parallaxImage && 
    <Parallax {...parallaxImage}>
      {renderParallaxChilds()}
    </Parallax>);
};

const scrollToElement = (id: string) =>
{
  if (document.location.href.endsWith(`#${id}`)) {
    window.scroll({ top: document.getElementById(id)?.offsetTop });
  }
}

const renderParallaxChilds = () : React.ReactNode => {
  return (
    <div className="absolute xs:bottom-8 md:inset-1/3">
      <h1 className="xs:text-2xl text-white md:text-black pt-10 sm:text-3xl m-2 font-Primary">
       Beauty Balance, uw schoonheidsspecialiste in Kampen
      </h1>
      <p className="m-2 text-white md:text-black">
        Wilt u lekker ontspannen met een diepe reiniging van uw huid?
        <br />
        Een heerlijke <strong>massage</strong>, epileren of ontharing met IPL?
      </p>
      <div className="md:flex sm:justify-between">
        <Link
          to={"/contact"}
          state={{ remark: "Graag een afspraak op:\nBehandeling:" }}
          title="Afspraak maken bij Beauty Balance"
          className="primaryButton block md:inline md:flex-1 mt-2 md:m-2"
        >
          Ik wil een afspraak maken
        </Link>
        <Link
          to={{ pathname: "shop" }}
          title="Product kopen bij Beauty Balance"
          className="secundaryButton block md:inline md:flex-1 mt-2 md:m-2"
        >
          Ik wil een product kopen
        </Link>
      </div>
    </div>
  );
};

const renderProductCategories = (
  productCategories: CardOverlayProps[] | null
) => {
  const numberOfproducts = productCategories?.length ?? 0;
  const col4: boolean = numberOfproducts > 3;
  return (
    productCategories && (
      <div className="bg-grey relative pt-10">
        <Slogan
          titleLevel="h2"
          titleClassName="text-center mb-0"
          title="Let your skin shine" />
        <section className="md:container md:ml-auto md:mr-auto text-center">
          <ul className={col4 ? "justify-center sm:grid grid-cols-2 justify-around lg:grid-cols-4" : "justify-center sm:grid justify-around grid-cols-2 md:grid-cols-3"}>
            {productCategories.map((a: CardOverlayProps, index) => (
              <CardOverlay key={index} {...a} />
            ))}
          </ul>
        </section>
      </div>
    )
  );
};
const renderFeaturedTreatment = (
  behandeling: Aanbieding | null,
) => {
  return (
    <section className="p-4 bg-grey">
      <TitleblockComponent
        titleLevel="h2"
        className="md:text-4xl text-center pt-10"
        title="Uitgelichte behandeling" />
      <article id="behandeling" className="lg:flex md:container md:ml-auto md:mr-auto newspaper xl:w-3/4">
        {behandeling && (
        <>
          <h3 className="mt-0">{behandeling.Naam}</h3><br/>
         <p
         dangerouslySetInnerHTML={{ __html: behandeling.Opmerking}}
          ></p>
         {
          behandeling.TweedeTekst && (
          <p
            dangerouslySetInnerHTML={{ __html:getTweedeColumnTest(behandeling)}}
          ></p>)
         }
          
          </>
        )}

      </article>
    </section>
  );
};

const renderNewsBlock = (
  nieuws: Nieuws | null,
  aanbiedingen: CardProps[] | null
) => {
  return (
    <section className="p-4">
      <TitleblockComponent
        titleLevel="h2"
        className="md:text-4xl text-center pt-10"
        title="Nieuws"
      ></TitleblockComponent>
      <article id="aanbieding" className="xl:flex md:container md:ml-auto md:mr-auto items-start">
        {nieuws && (
          <TextBlockComponent
            titleClassName="mb-0"
            title={nieuws.Titel}
            text={nieuws.Nieuwstekst}
            titleLevel="h3"
            className="xl:w-2/3"
          ></TextBlockComponent>
        )}
        {aanbiedingen && (
          <div className="md:flex xl:1/3 md:container md:ml-auto md:mr-auto">
            {aanbiedingen.map((a: CardProps, index) => (
              <Card key={index} {...a}></Card>
            ))}
          </div>
        )}
      </article>
    </section>
  );
};

const renderAbout = (info: ImageTextBlockProps | null) => {
  return (
    info && (
        <section className="relative md:container md:ml-auto md:mr-auto p-16 mb-4">
          <ImageTextOverlayBlock sectionCssClass="mb-16" {...info}></ImageTextOverlayBlock>
        </section>
    )
  );
};

const rendederAdditional = () => {
  return (
    <article  className="m-20 text-center">In mijn sfeervolle beautysalon aan de Motet 24 in Kampen sta ik voor u klaar met persoonlijke zorg en aandacht.<br/>
    Met vakkundig advies en de verzorgende behandeling die bij u past.<br/>
    Gun jezelf een moment van rust.. Wees Welkom.<br/>
    Neem gerust alvast een kijkje bij de verschillende gezichtsbehandelingen die mogelijk zijn in mijn salon.</article>
  );
}

export default HomePage;
function getTweedeColumnTest(behandeling: Aanbieding): string {
  return `${behandeling.TweedeTekst}<br/><i><a href=${behandeling.CtaUrl}>${behandeling.CtaNaam}</a></i>`;
}

