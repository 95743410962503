import React from 'react';
import ProductCatalogItem, { ProductCatalogItemProps } from './ProductCatalogItem';
import Titleblock from './Titleblock';

export interface ProductCatalogProps
{
    title:string;
    showInfo: boolean | null;
    catalog: ProductCatalogItemProps[] | null;
}

function renderProduct(index: number, product: ProductCatalogItemProps, showInfo: boolean|null)
{
    return (<ProductCatalogItem  key={index} className="relative bg-white text-gray-500 pt-2 mt-8 mx-4 rounded-t-xl text-center" showInfo={showInfo} { ... product} ></ProductCatalogItem>);
}


const ProductCatalog : React.FC<ProductCatalogProps> = (props: ProductCatalogProps) => {
return (
    <>
    <Titleblock className="text-center" title={props.title} titleLevel="h1"></Titleblock>
    <h2 className="text-center pt-10 text-black text-xl">In onze shop voeren wij voornamelijk Medex producten</h2>
    <p className="text-center">U kunt deze via de mail of telefonisch bestellen en ophalen in onze salon.</p>
    <section className="container ml-auto mr-auto p-4 text-center md:flex md:flex-wrap -mx-2 items-center">
        {props.catalog && props.catalog.map((x, index) => renderProduct(index,x, props.showInfo))}
    </section>
    </>
);
}

export default ProductCatalog;