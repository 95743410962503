import { ImageTextBlockProps } from "../Components/ImageTextBlockProps";
import { imageDef, PictureProps } from "../Components/Picture/PictureProps";

const overTiana = () : ImageTextBlockProps =>{
    let images : imageDef[] = new Array<imageDef>();
    images.push({ media: "(min-width: 100px)",  imageUrl: "/assets/images/tiana-schoonheidsspecialist-bij-beauty-balance-kampen-profiel.webp",sourceType: "image/webp"  });
    const picture: PictureProps = {
        images: images,
        width:"450",
        height:"300",
        lazy:false,
        imageClass:"ml-auto mr-auto md:ml-0 md:mr-0",
        defaultImageUrl:"/assets/images/tiana-schoonheidsspecialist-bij-beauty-balance-kampen-profiel.jpg",
        alt:"Schoonheidsspecialist Tiana Keijl-Dopmeijer"
        
    };
    const titleblock: ImageTextBlockProps = {
        picture: picture,
        title:"Uw schoonheidsspecialiste voorstellen",
        titleLevel: "h3",
        imageAlign:"left",
        paragaphcssClass: "md:absolute md:bottom-0 md:left-0 md:ml-48 p-4 md:rounded md:bg-grey md:bg-opacity-70",
        text: `In 2010 ben ik gestart met mijn eigen salon beauty balance.<br/>
        Graag help ik mensen om hun huid  in optimale conditie te krijgen en eventuele huidproblemen op te lossen.<br/>
        Elke huid is uniek en dit maakt mijn werk ook zo leuk.<br/>
        De waardering van de klanten is de reden voor mij om dit werk te doen en mij telkens  verder te ontwikkelen met de nieuwste technieken en producten.<br/> 
        <em><i>Let your skin shine</i></em>, want als je huid straalt en in optimale conditie is zit je lekkerder in je vel.<br/>
            <q class='font-Primary text-xl my-4'>Graag verwelkom ik jullie in mijn salon, Tiana</q>`
    }
    return titleblock;
}
export default overTiana;