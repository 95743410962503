import Picture from "./Picture/Picture";

import React from 'react';
import Titleblock from "./Titleblock";
import { ImageTextBlockProps } from "./ImageTextBlockProps";



export type ImageAlign = "left" | "right";

const ImageTextBlock = (props: ImageTextBlockProps) => {
    let classes: string = "md:flex content-start flex-wrap p-8";
    if (props.imageAlign === "left")
    {
        classes += " flex-row-reverse";
    }
    return (
            <article className={classes}>    
                <div className="md:w-1/2 text-left py-4">
                {props.title !== "" ? <Titleblock titleLevel={props.titleLevel} title={props.title}></Titleblock> : ""}
                <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
                </div>
                <div className="md:w-1/2 p-2">
                <Picture {...props.picture}></Picture>
                </div>
            </article>
        );
}

export default ImageTextBlock;