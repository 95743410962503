import React from 'react';

export interface AddressProps
{
    street: string,
    postalCode: string,
    city: string,
    phone: string,
    email:string,
}

const Address = (props: AddressProps) => {
    return  (
            <div className="">
                <div>{props.street}</div>
                <div>{props.postalCode}&nbsp;{props.city}</div>
            </div>
            );
}

export default Address;