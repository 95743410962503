import React from 'react';
import { PictureProps } from './PictureProps';





const Picture = (props: PictureProps) => {
    return (
        <picture className={`${props.pictureClass || ''}`}>
          {props.images.map((a) => (
            <source key={a.media} media={a.media} srcSet={a.imageUrl} type={a.sourceType} width={a.width} height={a.height} />
          ))}
          <img 
            loading={props.lazy ?  'lazy' : 'eager'}
            width={props.width ? props.width : ''}
            height={props.height ? props.height : ''}
            className={`${props.imageClass || ''}`}
            src={props.defaultImageUrl}
            alt={props.alt}
            title={props.alt}
          ></img>
        </picture>
    );
}

export default Picture;