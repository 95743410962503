import React from 'react';
import { ProductCatalogItemProps } from '../ProductCatalogItem';
import CartItem, { CartItemProps } from './CartItem';

export interface CartOverviewProps
{
    cartProducts: CartItemProps[];
    addProductToCart(item:ProductCatalogItemProps): void;
    removeProductFromCart(item:ProductCatalogItemProps): void;
}

const renderCartItem = (index: number, product: CartItemProps, props: CartOverviewProps ) =>
{
    return (<CartItem quantity={product.quantity} key={index} product={product.product} addProductToCart={props.addProductToCart} removeProductFromCart={props.removeProductFromCart}  ></CartItem>);
}

const CartOverview : React.FC<CartOverviewProps> = (props: CartOverviewProps) => {
    return (<>
    <section>
        {props.cartProducts && props.cartProducts.map((x, index) => renderCartItem(index,x, props))}
        </section></>);
}

export default CartOverview;