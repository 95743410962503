import { CardOverlayProps } from "../Components/CardOverlay";

const mainProductCategoryItems = (): CardOverlayProps[] =>{
    let items : CardOverlayProps[] = new Array<CardOverlayProps>();
    
    items.push({ title:"Bindweefselmassage", 
            actionName:"vanaf EUR 65,00", 
            actionUrl:"/gezichtsbehandeling/bindweefselmassage", 
            picture: {
                picture : {
                    images: [{ media: "(min-width: 200px)", imageUrl: "/assets/images/bindweefselmassage.webp", sourceType: "image/webp" }],
                    alt: "massage - bindweefsel",
                    width:"250",
                    height:"250",
                    lazy:false,
                    defaultImageUrl : "/assets/images/bindweefselmassage.webp"
                }
            }
    });
    items.push({ title:"Lashlift", 
    actionName:"Lashlift", 
    actionUrl:"/gezichtsbehandeling", 
    picture: {
        picture : {
            images: [{ media: "(min-width: 200px)", imageUrl: "/assets/images/lashlift.webp", sourceType: "image/webp" }],
            alt: "lashlift door Tiana bij Beauty Balance, Motet 24 Kampen",
            width:"250",
            height:"250",
            lazy:false,
            defaultImageUrl : "/assets/images/lashlift.jpg"
        }
    }});
    items.push({ title:"Airbrush Brows",
    actionName:"Airbrush Brows", 
    actionUrl:"/gezichtsbehandeling/hybride-airbrush-brows", 
    picture: {
        picture : {
            images: [{ media: "(min-width: 200px)", imageUrl: "/assets/images/airbrush-brows.jpg", sourceType: "image/webp"  }],
            alt: "hybrid en airbrush brows",
            width:"250",
            height:"250",
            lazy: false,
            defaultImageUrl : "/assets/images/airbrush-brows.jpg"
        }
    }});
    return items;
}

export default mainProductCategoryItems;