import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductCatalogItem, { ProductCatalogItemProps } from "../Components/ProductCatalogItem";
import ProductCatalogMetaData from "../Components/ProductCatalogMetaData";
import TitleTag from "../Components/TitleTag";
import { getProductCatalogItems } from "../Services/productCatalogService";

interface ShopProductPageProps {
  addProductToCart(item: ProductCatalogItemProps): void;
  removeProductFromCart(item: ProductCatalogItemProps): void;
  title?: string,
}
type ParamTypes = {
  productName: string
}

const ShopProductPage: React.FC<ShopProductPageProps> = (props: ShopProductPageProps) => {
  let param = useParams<ParamTypes>();
  const [products, setProducts] = useState<ProductCatalogItemProps[] | null>(null);
  const [product, setProduct] = useState<ProductCatalogItemProps>();
  useEffect(() => {
    getProductCatalogItems().then((x) => {
      let product = x.find(y=>y.tagName === param.productName);
      if (product){
        product.addToCart = AddToCartAndredirectToShop;
        product.removeFromCart = props.removeProductFromCart;
        setProduct(product);
        let foundProducts = new Array<ProductCatalogItemProps>();
        foundProducts.push(product);
        setProducts(foundProducts);
      } 
     
    });
  }, [props.addProductToCart, props.removeProductFromCart]);

  function AddToCartAndredirectToShop(item: ProductCatalogItemProps){
    props.addProductToCart(item);
    window.location.href=`/shop#${item.tagName}`;
  }
 
  return (
    <section className="bg-grey p-2">
      <TitleTag
        title={props.title ?? "Medex producten"}
        description={`${props.title ?? "Medex"} kopen bij Beauty balance Kampen`}
        date="2021-03-09"
        canonical={`https://www.tiana.nu/shop/${param.productName}`}
      />
      <section className="container ml-auto mr-auto md:p-10">
        <h1 className="mb-10">{product?.subTitle}</h1>
        <div className="p-4 text-center md:flex md:flex-wrap -mx-2">
      {product && <ProductCatalogItem className="relative bg-white text-gray-500 pt-2 mx-4 rounded-xl text-center" showInfo={false} {...product} ></ProductCatalogItem>}
        <p className="bg-white ml-4 mt-0 p-4 rounded-xl md:flex-1"><p dangerouslySetInnerHTML={{ __html: product?.description ?? "uw product kunt u ophalen bij onze salon" }}></p> <div className="w-full mt-8 text-right">
       <a href={`/shop#${product?.tagName}`}>Naar shop &gt;</a></div></p>
      </div>
     
      {product && <ProductCatalogMetaData products ={products}></ProductCatalogMetaData>}
      </section>
    </section>
  );
};

export default ShopProductPage;
