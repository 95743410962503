import React from 'react';
import Titleblock from './Titleblock';

export interface PriceListItemProps
{
    name: string;
    nameClasses?:string;
    price: string;
    priceClasses?:string;
}

const PriceListItem : React.FC<PriceListItemProps> = (props: PriceListItemProps) => {
    return (<><Titleblock titleLevel="h4" title={props.name} className={props.nameClasses || ""} ></Titleblock>
            <span className={props.priceClasses || ""}>{props.price}</span>
            </>);
}

export default PriceListItem;