import { AddressProps } from "../Components/Address";

const getAddress = () : AddressProps =>{
    return {
        street:"Motet 24",
        postalCode:"8265 RJ",
        city:"Kampen",
        phone:"06 - 410 92 602",
        email:"info@tiana.nu"
    }
}

export default getAddress;