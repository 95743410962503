import React from 'react';

export interface LinkProps
{
    url:string,
    name:string,
    title:string,
    classname?:string,
    target?:string,
    children?: JSX.Element
}

const Link : React.FC<LinkProps> = ({title, name, url, classname, target, children}) => {
return <a href={url} title={title} 
        {...(classname !== "" ? {className: classname} : {})}
        {...(target ? {target: target} : {})}
        >{children || name}</a>;
}

export default Link;