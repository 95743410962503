import React from "react";
import {Link} from "react-router-dom";
import Picture from "../Components/Picture/Picture";
import {PictureProps} from "../Components/Picture/PictureProps";
import TitleTag from "../Components/TitleTag";

export interface AanbiedingPageProps {
    pageTitle: string;
    pageDescription: string;
    pageCanonical: string;
    headerPicture: PictureProps;
    title: string;
}

const AanbiedingPage: React.FC<AanbiedingPageProps> = (
    props: AanbiedingPageProps
) => {
    return (
        <>
            <TitleTag
                title={props.pageTitle}
                description={props.pageDescription}
                canonical={props.pageCanonical}
                date="01-10-2021"
            />
            <div className="w-full relative">
                <Picture imageClass="w-full" {...props.headerPicture} />
                <div className="absolute bottom-0 left-0 flex p-4 bg-lightviolet w-full">
                    <h1 className="text-4xl pt-4 sm:text-3xl md:text-5xl m-2 font-Primary text-white font-normal">
                        {props.title}
                    </h1>
                </div>
            </div>
            <div className="bg-grey">
                <section className="container ml-auto mr-auto p-4">
                    <h2>Medex Boost Behandeling</h2>
                    <p>De boost behandeling voor je huid met het Toscane Thermo Mask, het modellerend mineraal
                        thermomasker dat tot wel 45 graden opwarmt op de huid.&nbsp;
                        <strong>Nu voor 75,- euro</strong></p>
                    <ul>
                        <li>4 fasen reiniging</li>
                        <li>Aanbrengen van hoog geconcentreerde werkstoffen</li>
                        <li>Toscane thermomasker ( gipsmasker dat opwarmt tot 45 graden celcius, de huid wordt hierdoor<br/>
                            optimaal toelaatbaar voor de werkstoffen die worden aangebracht
                        </li>
                        <li>Oogcreme, serum</li>
                        <li>Transparant collageen mask</li>
                        <li>Ampul</li>
                        <li>Dag of nachtcreme</li>

                    </ul>
                    <p className="mt-2">
                        <Link
                            to={"/contact"}
                            state={{remark: "Graag een afspraak op:\nBehandeling: Medex boost"}}
                            title="Afspraak maken bij Beauty Balance"
                            className="primaryButton block md:inline md:flex-1 mt-2 md:m-2"
                        >
                            Ik wil een afspraak maken voor de Medex Boost
                        </Link>
                    </p>
                </section>
            </div>
        </>
    );
};

export default AanbiedingPage;
