import React from 'react';
import Link from './Link';
import {IconLinkProps} from './IconLink';
   
  
   
interface IconTextLinkProps extends IconLinkProps
{
  iconurl:string;
  iconClassName?:string;
  width:string;
  height:string;
  alt:string;
}

const IconTextLink: React.FC<IconTextLinkProps> = (props: IconTextLinkProps) => {
   
return (
    <>
      <img src={props.iconurl} className={props.iconClassName} width={props.width} height={props.height} alt={props.alt} />
      <Link {... props}></Link>
    </>);
}

export default IconTextLink;