import React from "react";
import PrimaryButton from "./PrimaryButton";
import Slogan from "./Slogan";

export interface ContactFormProps {
  title: string;
  confirmationTitle: string;
  name: string;
  email:string;
  phone:string;
  remark:string;
  remarkField: string;
  onInputChange(name:string, value: string): void;
  onSubmit(event: React.FormEvent<HTMLFormElement>): void;
}

const ContactForm: React.FC<ContactFormProps> = (props: ContactFormProps) => {
  

  return (
    <div className="bg-grey p-1 lg:p-8">
        <Slogan titleLevel='h2' titleClassName="text-center" title={props.title}></Slogan>
        <form onSubmit={props.onSubmit}>
      <div className="relative m-1 lg:m-6">
          <label htmlFor="name" className="bg-darkgrey inline-block align-top absolute pl-2 pt-2 left-0 top-0 bottom-0 w-28 rounded-l-lg">Naam:</label>
        <input
          type="text" name="name" value={props.name} onChange={e=> props.onInputChange(e.target.name, e.target.value)}
          className="bg-white focus:outline-none rounded-lg py-2 pr-4 pl-28 w-full appearance-none leading-normal text-purple"
        ></input>
      </div>
      <div className="relative m-1 lg:m-6">
      <label htmlFor="email" className="bg-darkgrey inline-block align-top absolute w-28 pl-2 pt-2 left-0 top-0 bottom-0 rounded-l-lg">Email:</label>
        <input
          type="email"
          name="email"
          value={props.email}
          onChange={e => props.onInputChange(e.target.name, e.target.value)}
          className="bg-white focus:outline-none border-gray-300 rounded-lg py-2 pr-4 pl-28 w-full text-purple"
        ></input>
      </div>
      <div className="relative m-1 lg:m-6">
      <label htmlFor="phone" className="bg-darkgrey inline-block align-top absolute w-28 pl-2 pt-2 left-0 top-0 bottom-0 rounded-l-lg">Telefoon:</label>
        <input
          type="phone"
          name="phone"
          value={props.phone}
          onChange={e=> props.onInputChange(e.target.name, e.target.value)}
          className="bg-white focus:outline-none border-gray-300 rounded-lg py-2 pr-4 pl-28 w-full text-purple"
        ></input>
      </div>
      <div className="relative m-1 lg:m-6">
      <label htmlFor="remark" className="bg-darkgrey height-120 inline-block align-top absolute pl-2 pt-2 left-0 top-0 bottom-0 w-28 rounded-l-lg">{props.remarkField}</label>
        <textarea name="remark" value={props.remark} onChange={e=>props.onInputChange(e.target.name, e.target.value)} className="bg-white height-120 focus:outline-none rounded-lg py-2 pr-4 pl-28 w-full text-purple"></textarea>
      </div>
        <PrimaryButton type="submit" name={props.confirmationTitle} classname="w-full"  />
      </form>
    </div>
  );
};
export default ContactForm;
