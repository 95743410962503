import { ImageTextBlockProps } from "../Components/ImageTextBlockProps";
import { imageDef, PictureProps } from "../Components/Picture/PictureProps";

const epilerenText = () : ImageTextBlockProps =>{
    let images : imageDef[] = new Array<imageDef>();
    images.push({ media: "(min-width: 100px)", imageUrl: "/assets/images/epileren-verven-harsen.webp", sourceType: "image/webp"  });
   
    const picture: PictureProps = {
        images: images,
        defaultImageUrl:"/assets/images/epileren-verven-harsen.jpg",
        alt:"Epileren - verven - harsen",
        imageClass:"center md:float-right rounded md:m-8"
    };
    const titleblock: ImageTextBlockProps = {
        picture: picture,
        title:"",
        titleLevel: "h2",
        imageAlign:"left",
        text: `Epileren is een ontharingsmethode waarbij uw lichaamshaar met
        haarwortel en al verwijderd wordt.
        <br />
        Hiermee onderscheidt deze vorm zich tot veel andere ontharingsmethodes
        (scheren, ontharing crème e.d.), waarbij u alleen het zichtbare (bovenhuidse) gedeelte van de haartjes
        verwijdert.
        <h3 class="text-xl font-bold text-secundary mt-4">
          De voordelen van epileren
        </h3>
        Epileren heeft een aantal belangrijke voordelen ten opzichte van
        andere ontharingsmethoden.
        <br />
        De belangrijkste voordelen vind u hieronder:
        <h3 class="text-xl font-bold text-secundary mt-4">
          Grondige methode
        </h3>
        Epileren is een grondige methode voor lichaamsontharing en daardoor is
        het resultaat veel blijvender dan tijdelijke ontharingsmethoden,
        <br />
        zoals scheren of ontharing crèmes waarmee de haartjes vaak al weer na
        enkele dagen veelvuldig terugkomen.
        <h3 class="text-xl font-bold text-secundary mt-4">
          Beter resultaat
        </h3>
        Verder is het zo dat epileren een veel mooiere en strakkere vorm in de
        wenkbrauwen geeft en dat u geen harde stoppels krijgt na het
        verwijderen van de haartjes. Wanneer de haartjes weer terug groeien
        ontstaan er weer zachte haartjes omdat de haar weer vanaf het begin
        moet gaan groeien.`
    }
    return titleblock;
}
export default epilerenText;