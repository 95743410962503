import React from "react";
import { toDutchCurrency } from "../Services/general/currencyService";
import Picture from "./Picture/Picture";
import { PictureProps } from "./Picture/PictureProps";
import Titleblock from "./Titleblock";

export interface ProductCatalogItemProps {
  title: string;
  subTitle?: string;
  tagName: string;
  price: number;
  className?: string;
  picture: PictureProps;
  id: string;
  priceClassName?: string;
  description?: string;
  addToCart(item: ProductCatalogItemProps): void;
  removeFromCart(item: ProductCatalogItemProps): void;
  showInfo?: boolean | null;
}

const ProductCatalogItem: React.FC<ProductCatalogItemProps> = (props: ProductCatalogItemProps) => {
  return (

    <div className="relative">
      <div className={props.className || ''} id={createKey(props)}>
        <Titleblock titleLevel="h4" title={props.title}></Titleblock>
        <Picture lazy={true} {...props.picture} />
      </div>
      <div className="flex mx-4 bg-gray-200 rounded-b-xl">
        <div className={`w-3/4 whitespace-nowrap text-right ${props.priceClassName}`}>
          {props.subTitle && <Titleblock titleLevel="h5" className="font-medium block" title={props.subTitle}></Titleblock>}
          <div className="flex">
            {(props.showInfo === null || props.showInfo === true) &&
              <div className="flex-1 text-left pl-4 -mt-1"><a title={`informatie over ${props.subTitle}`} className="rounded-full bg-gray-100 p-1" href={`/shop/${props.tagName}`}>info</a></div>
            }
            <div className="flex-1">{toDutchCurrency(props.price)}</div>
          </div>
        </div>
        <div className="w-1/4 px-2 text-center pt-1">
          <button className="bg-purple p-2 rounded" value={props.id} id={props.id} onClick={x => { props.addToCart(props); }}><img className="fill-current" src="/assets/images/basket.png" width="30" height="30" title="toevoegen aan winkelmand" alt="toevoegen aan winkelwagen" />  </button>
        </div>
      </div>
    </div>
  );
};

const createKey = (props: ProductCatalogItemProps): string => {
  if (props.id) {
    return props.id;
  } else if (props.subTitle) {
    var re = / /gi;
    const keyname = props.subTitle.replace(re, "_").replace("<br/>", "_");
    return keyname;
  } else {
    var re2 = / /gi;
    const keyname = props.title.replace(re2, "_").replace("<br/>", "_");
    return keyname;
  }
}

export default ProductCatalogItem;
