import React from 'react';

interface PrimaryButtonProps
{
    name: string;
    classname:string
    OnClickHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    type: ButtonType
}

export type ButtonType = "button" | "submit" | "reset";

const PrimaryButton = (props: PrimaryButtonProps) => {
    return <button className={`${props.classname} | primaryButton`} type={props.type} onClick={props.type==="button" ? props.OnClickHandler: undefined}>{props.name}</button>;
}

export default PrimaryButton;