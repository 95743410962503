import React from 'react';
import { toDutchCurrency } from '../../Services/general/currencyService';
import Picture from '../Picture/Picture';
import { ProductCatalogItemProps } from '../ProductCatalogItem';

export interface CartItemProps
{
    product: ProductCatalogItemProps;
    quantity: number;
    addProductToCart(item:ProductCatalogItemProps): void;
    removeProductFromCart(item:ProductCatalogItemProps): void;
}

const CartItem : React.FC<CartItemProps> = (props: CartItemProps) => {
    const totalValue = props.quantity * props.product.price;

    return (<><div className="flex">
                <Picture {... props.product.picture} imageClass="" width="80"></Picture>
                <div className="flex w-full flex-wrap">
                    <div className="w-full pl-2 text-lg">{props.product.subTitle}</div>
                    <div className="flex flex-wrap m-2"><button onClick={x=>  { props.removeProductFromCart(props.product);}} className="bg-lightpurple center h-6 w-6 text-center self-center text-gray">-</button><input type="number" className="self-center bg-gray-100 h-6 w-8 text-center m-0" value={props.quantity} /><button className="bg-violet block center h-6 w-6 text-center self-center text-white" onClick={x=>  { props.addProductToCart(props.product);}}>+</button></div>
                    <div className="text-right md:text-center self-center flex-grow pr-4">{toDutchCurrency(totalValue)}</div>
                </div>
            </div>
            </>);
}

export default CartItem;