import {useState, useEffect} from 'react'

export default function useFetch(url: string) {
  
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(()=>
    {
    
      async function getData(){
        try{
        const response = await fetch(url);
        if (response.ok){
          const json = await response.json();
          setData(json);
        }else{
          throw response;
        }
        }
        catch(e)
        {
          setError(null);
        }
      }
      getData();
    }
    ,[url]);
    return {data, error}
}


export async function getData<T>(url: string){
  const response = await fetch(url);
  const data = await response.json();
  return data as T;
}
