import { LinkProps } from "../Components/Link";


export const tagItems = (): LinkProps[] =>{
    let items : LinkProps[] = new Array<LinkProps>();
    
    items.push(
        {
            name: "Schoonheidsspecialist Kampen",
            url: "/schoonheidsspecialisten-kampen",
            title: "Kampen schoonheid specialist Beauty Balance Tiana"
        }
    );
    items.push(
        {
            name: "Schoonheidsspecialist Zwolle",
            url: "/schoonheidsspecialisten-zwolle",
            title: "Zwolle - Schoonheidsspecialist Beauty Balance Tiana"
        }
    );
    items.push(
        {
            name: "Schoonheidsspecialist IJsselmuiden",
            url: "/schoonheidsspecialisten-ijsselmuiden",
            title: "Ijsselmuiden - Schoonheidsspecialist Beauty Balance Tiana"
        }
    );
   
    return items;
}

export const footerLinkItems = (): LinkProps[] =>{
    let items : LinkProps[] = new Array<LinkProps>();
    
    items.push(
        {
            name: "Behandelingen",
            url: "/gezichtsbehandeling",
            title: "Gezichtsbehandeling schoonheidsspecialiste Beauty Balance Kampen"
        }
    );
    items.push(
        {
            name: "IPL",
            url: "/ipl-duurzaam-ontharen",
            title: "Gezichtsbehandeling schoonheidsspecialiste Beauty Balance Kampen"
        }
    );
    items.push(
        {
            name: "Acne behandelingen",
            url: "/gezichtsbehandeling/acne",
            title: "Acne behandeling Beauty Balance Tiana"
        }
    );
    items.push(
        {
            name: "Massages",
            url: "/massages",
            title: "Massages bij Beauty Balance Tiana"
        }
    );
   
    return items;
}
export default tagItems;