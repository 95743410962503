import React from 'react';
import Address from './Address';
import Bank from './Bank';
import Tags from './Tags';
import tagItems, { footerLinkItems } from '../Services/tagsService';
import getAddress from '../Services/addressService';

interface PageFooterProps
{

}

const PageFooter = (props: PageFooterProps) => {
    return  (<footer className="bg-darkpurple text-grey font-thin">
    <section className="container ml-auto mr-auto pt-8 px-4 pb-4">
        <div className="md:grid md:grid-cols-3 sm:grid-cols-2 gap-4 text-xs">
            <div className="m-2">Adres:<br/><Address {... getAddress()}></Address></div>
            <Bank KVK="Kvk nr: 08 22 42 98" Rek1="Btw nr: NL001591444B97" Rek2="Bank: NL21 INGB 0005 3848 88" ></Bank>
            <Tags links={tagItems()} ></Tags>
        </div>
        <div className="md:grid md:grid-cols-3 sm:grid-cols-2 gap-4 text-xs">
            <Tags links={footerLinkItems()}></Tags>
        </div>
    </section>
</footer>);
}

export default PageFooter;