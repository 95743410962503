import React from 'react';
import Picture from '../Components/Picture/Picture';
import PriceList from '../Components/PriceList';
import { ipl_gezichtPrizes, ipl_lichaamPrizes, ipl_ledematenPrizes } from '../Services/priceLists';
import Slogan from '../Components/Slogan';
import TitleTag from '../Components/TitleTag';

interface IplPageProps {
}

const massageHeader = {
    images: [{ media: "(min-width: 800px)", imageUrl: "/assets/images/ipl-header.jpg", sourceType: "images/jpeg" },
    { media: "(max-width: 640px)", imageUrl: "/assets/images/ipl-640.jpg", sourceType: "images/jpg" }],
    defaultImageUrl: "/assets/images/ipl-header.jpg",
    alt: "IPL bij Beauty Balance"
}

const IplPage = (props: IplPageProps) => {
    return (<>
        <TitleTag title="IPL ontharen" canonical="https://www.tiana.nu/ipl-duurzaam-ontharen" date="14-03-2021" description="Intense Pulse Light, IPL; veiliger dan laserbehandeling, ontharen met haarreductie tot 90% vanaf 25,00 Euro per behandeling" />
        <div className="w-full relative">
            <Picture imageClass="w-full" defaultImageUrl={massageHeader.defaultImageUrl} alt="IPL " images={massageHeader.images}></Picture>
            <div className="absolute bottom-0 left-0 flex p-4 bg-lightviolet w-full">
                <h1 className="text-4xl pt-4 sm:text-3xl md:text-5xl m-2 font-Primary text-white font-normal">IPL duurzaam ontharen</h1>
            </div>
        </div>
        <div className="bg-grey">
            <Slogan titleLevel='h2' titleClassName="text-center pt-10" title="Nooit meer harsen, scheren of epileren! IPL, iets voor u?"></Slogan>
            <section className="container ml-auto mr-auto mt-8 pb-8">
                <p>Duurzaam ontharen d.m.v. IPL behandeling
                IPL behandeling in het kort:
                <ul>
                        <li>voor een optimaal resultaat zijn gemiddeld 12 behandelingen nodig</li>
                        <li>kosten per behandeling variëren van 25,- tot 275,-</li>
                        <li>veiliger dan laserbehandeling</li>
                        <li>85% tot 90% haarreductie</li>
                    </ul>
                    <i>Beauty Balance heeft inmiddels al jaren ervaring op gebied van duurzaam ontharen</i>
                </p>
            </section>
        </div>
        <section className="container ml-auto mr-auto mt-8">
            <p>
                <h2>Hoe werkt IPL?</h2>
                <i>IPL betekent ‘Intense Pulse Light’ en staat voor hoogenergetisch licht dat gepulseerd op de huid wordt gericht. Tijdens de IPL behandeling wordt een zogenaamde ‘lichtflits’ op de huid afgegeven. Dit licht wordt omgezet in warmte en geabsorbeerd door het pigment dat zich in de haren bevind. De warmte wordt door het haar geleid naar de haarwortel. De haarwortel wordt hierdoor vernietigd en er kan uit de haarwortel geen nieuwe haar meer groeien.</i>
                Waarom zijn er meerder behandelingen nodig?<br />
                Onze haren hebben een groeicyclus. Deze groeicyclus bestaat uit 3 fasen.<br />
                <em>Deze fasen noemen we respectievelijk de:</em><br />
                <ul>
                    <li>Groeifase (Anagene fase)</li>
                    <li>Overgangsfase (Katagene fase)</li>
                    <li>Rustfase (Telogene fase)</li>
                </ul>
            </p>

            Deze fasen volgen elkaar op en de tijd tussen de fases wordt bepaald door de plaats waar de haren groeien. Zo duurt de groeicyclus op de benen veel langer dan in het gelaat. Alleen de haren die zich in de groeifase (anagene fase) bevinden reageren op de IPL behandeling. Dit verklaart waarom er altijd meerdere behandelingen noodzakelijk zijn en waarom er verschillende tussenpozen tussen de behandelingen nodig zijn.
            </section>
        <section className="container ml-auto mr-auto my-12">

            <h3>Welk resultaat mag u verwachten?</h3>
            U mag een resultaat van 85% tot 90% haargroeireductie verwachten.
            Belangrijk is dat u de adviezen goed opvolgt. Zo mag uw huid gedurende de kuur niet blootgesteld worden aan UV-straling. Waarom? Het IPL licht wordt geabsorbeerd door pigment. Bevind zich teveel pigment in de huid dan deze ook energie absorberen waardoor er minder energie naar uw haar kan gaan. Daarnaast zijn de tussenpozen tussen de behandelingen erg belangrijk. Zit er een te lange tijd tussen de behandelingen en is de haarwortel nog net niet volledig vernietigd bestaat de kans op herstel van de haarwortel.

            <h3>Wat is het voordeel van IPL t.o.v Laserbehandeling?</h3>
            Laserlicht heeft  de vorm van een nauwe, zich weinig verspreidende lichtbundel van 1 golflengte. Bij IPL hebben we te maken met een meer diffuus licht van meerdere golflengtes.
            Het grote nadeel van de laser is dat ze zo´n nauwe lichtbundel heeft met een hele hoge energetische waarde. Dit kan gevaarlijk zijn en hierdoor is de kans op bijverschijnselen vele malen groter. Denk dan vooral aan verbrandingsverschijnselen van de huid. Doordat we bij IPL te maken hebben met een diffuus licht is het vele malen veiliger voor de huid. Daarnaast is de IPL meer flexibel dan de laser omdat we met meerdere golflengtes te maken hebben. Zo kan met behulp van filters het spectrum gedeeltelijk aangepast worden.
            </section>
        <div className="bg-grey">
            <Slogan titleLevel='h2' titleClassName="text-center pt-10" title="Werkt IPL bij iedereen?"></Slogan>
            <section className="container ml-auto mr-auto py-6">
                <p className="mt-8">
                    Er zijn een aantal voorwaarden om voor IPL behandeling in aanmerking te komen.<br />
                Zo moeten de haren pigment bevatten! IPL werkt dus niet bij blonde, grijze en rode haren.<br />
                Tevens is de huidskleur belangrijk. Hoe meer pigment de huid bevat hoe minder effectief de behandeling zal zijn. Bij mensen met een negroïde huidskleur kan de behandeling helaas niet uitgevoerd worden.<br />
                Het meest effectief is de behandeling bij een lichte huid met donkere haren.<br />
                Daarnaast hebben we in sommige gevallen te maken met een overbeharing die o.i.v. hormonen tot stand komt. Deze vorm van overbeharing is vele malen hardnekkiger en lastiger te behandelen.<br />
                Ook moet rekening gehouden worden met de hormonale veranderingen van man en vrouw. Een blonde zachte haar kan zich in de loop der jaren ontwikkelen tot een dikke donkere haar. Zo kan het dus zijn dat er in de loop van de jaren toch weer wat donkere haren ontwikkelen. Op het moment van behandelen waren deze haren nog blond en dus niet gevoelig voor de behandeling. Dit proces zien we vaak bij vrouwen in het gelaat.
                </p>
                <h3>Waarom uw IPL behandeling bij Beauty Balance?</h3>
            Tiana Dopmeijer heeft inmiddels jaren lange ervaring op het gebied van IPL behandelingen.<br />
            Daarnaast heeft Tiana Dopmeijer door haar kennis en inzicht in de huid, een efficiënte maar bovenal veilige procedure ontwikkeld waardoor een optimaal resultaat gewaarborgd is.<br />
            </section>
        </div>
        <section className="container ml-auto mr-auto my-6">
            <Slogan titleLevel='h2' titleClassName="text-center pt-10" title="Prijslijst"></Slogan>
            <div className="lg:flex px-2 my-6">
                <div className="lg:w-1/3 mx-2 lg:mx-12 pricelist">
                    <h2 className="font-Primary text-xl text-secundary font-normal">Gezicht ontharing</h2>
                    <PriceList {...ipl_gezichtPrizes()} />
                </div>
                <div className="lg:w-1/3 mx-2 lg:mx-12  pricelist">
                    <h2 className="font-Primary text-xl text-secundary font-normal">Lichaam ontharing</h2>
                    <PriceList {...ipl_lichaamPrizes()} />
                </div>
                <div className="lg:w-1/3 mx-2 lg:mx-12 pricelist">
                    <h2 className="font-Primary text-xl text-secundary font-normal">Ledematen ontharing</h2>
                    <PriceList {...ipl_ledematenPrizes()} />
                </div>
            </div>
        </section>
    </>);
}

export default IplPage;