import { CardProps } from "../Components/Card";
import { AanbiedingPageProps } from "../Pages/AanbiedingPage";
import { getData } from "./general/httpDataService";

export interface Aanbieding {
  Naam: string;
  Opmerking: string;
  TweedeTekst: string | null;
  CtaNaam: string;
  CtaUrl: string;
  Afbeelding: AanbiedingAfbeelding[]
}
interface AanbiedingAfbeelding {
  url: string,
  name: string,
  alternativeText: string,
  caption: string,
  mime: string
}

export interface Nieuws {
  Titel: string,
  Nieuwstekst: string
}


export async function getNieuws(): Promise<Nieuws> {
  return await getData<Nieuws>("/assets/nieuws20220225.json") as Nieuws;
}

export async function getUitgelichteBehandeling(): Promise<Aanbieding> {
  return await getData<Aanbieding>("/assets/uitgelichtebehandeling20231208.json") as Aanbieding;
}

export async function aanbiedingVanDeMaandItems(): Promise<CardProps[]> {

  let items: CardProps[] = new Array<CardProps>();
  
  const productAanbieding: Aanbieding | null = await getData<Aanbieding>("/assets/productaanbieding20220225.json");
  if (productAanbieding !== null) {
    items.push({
      title: productAanbieding.Naam,
      content: productAanbieding.Opmerking,
      actionName: productAanbieding.CtaNaam,
      actionUrl: productAanbieding.CtaUrl, picture: {
        images: [{ media: "(min-width: 300px)", imageUrl: productAanbieding.Afbeelding[0].url, sourceType: productAanbieding.Afbeelding[0].mime }],
        alt: productAanbieding.Afbeelding[0].alternativeText,
        width: "100%",
        height: "100%",
        lazy:true,
        defaultImageUrl: productAanbieding.Afbeelding[0].url
        , imageClass: "ml-auto mr-auto"
      }
    });
  }
  const behandelAanbieding: Aanbieding | null = await getData<Aanbieding>("/assets/behandelaanbieding20220314.json");
  if (behandelAanbieding !== null) {
    items.push({
      title: behandelAanbieding.Naam,
      content: behandelAanbieding.Opmerking,
      actionName: behandelAanbieding.CtaNaam,
      actionUrl: behandelAanbieding.CtaUrl, picture: {
        images: [{ media: "(min-width: 300px)", imageUrl: behandelAanbieding.Afbeelding[0].url, sourceType: behandelAanbieding.Afbeelding[0].mime }],
        alt: behandelAanbieding.Afbeelding[0].alternativeText,
        width: "100%",
        height: "100%",
        defaultImageUrl: behandelAanbieding.Afbeelding[0].url
        , imageClass: "ml-auto mr-auto"
      }
    });
  }
  items.push({
    title: "Gezicht behandeling<br/>at home",
    content: "Gezichtsbehandeling voor thuis",
    actionName: "Voor 15,00 >",
    actionUrl: "/gezichtsbehandeling/voor-thuis",
    picture: {
      images: [{ media: "(min-width: 300px)", imageUrl: "/assets/images/gezichtsbehandeling-voor-thuis-aanbieding.webp", sourceType: "image/webp" }],
      alt: "7 stappen gezichtsbehandeling voor thuis",
      width: "100%",
      height: "100%",
      defaultImageUrl: "/assets/images/gezichtsbehandeling-voor-thuis-aanbieding.jpg", imageClass: "ml-auto mr-auto"
    }
  });
  return items;
}

export const getBehandelingPage = (): AanbiedingPageProps => {
  const headerImage = {
    images: [
      {
        media: "(max-width: 640px)",
        imageUrl: "/assets/images/aanbieding-van-de-maand-640.webp",
        sourceType: "image/webp",
      },
      {
        media: "(min-width: 800px)",
        imageUrl: "/assets/images/aanbieding-van-de-maand-oktober.webp",
        sourceType: "image/webp",
      },
    ],
    defaultImageUrl: "/assets/images/aanbieding-van-de-maand-oktober.jpg",
    alt: "Medex Boost behandeling maart tiana",
  };

  const generalHeader = {
    images: [{ media: "(min-width: 800px)", imageUrl: "/assets/images/beauty-balance-welkom-800.webp", sourceType: "image/webp" },
    { media: "(max-width: 799px)", imageUrl: "/assets/images/beauty-balance-welkom-300.webp", sourceType: "image/webp" }],
    defaultImageUrl:"/assets/images/beauty-balance-welkom-800.jpg",
    alt:"Medex Boost behandeling bij Beauty Balance Tiana Kampen"
}
  return {
    headerPicture: generalHeader,
    pageCanonical: "http://wwww.tiana.nu/behandeling-van-de-maand",
    pageDescription: "Medex Boost behandeling in de maand maart 2022 voor EUR 75,00",
    pageTitle: "Medex Boost behandeling in maart 2022 voor 85,00",
    title: "Medex Boost behandeling"
  }
}