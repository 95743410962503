import React from 'react';
import Link, {LinkProps} from './Link';
export interface IconLinkProps extends LinkProps
{
  iconName: string;
  library:string;
  iconurl:string;
  iconClassName?:string;
  width:string;
  height:string;
  alt:string;
}

const IconLink = (props: IconLinkProps) => {
   
    return (
      <Link {...props}>
         <img src={props.iconurl} className={props.iconClassName} width={props.width} height={props.height} alt={props.alt} />  
      </Link>
      );
}

export default IconLink;