import React, { useEffect, useState } from 'react';
import Picture from '../Components/Picture/Picture';
import PriceList, { PriceListProps } from '../Components/PriceList';
import TitleTag from '../Components/TitleTag';
import { getBehandelingenProducts } from '../Services/productCardService';

interface PrijslijstPageProps
{

}
const pageHeader = {
    images: [
      {
        media: "(min-width: 800px)",
        imageUrl: "/assets/images/massages-beauty-balance-header.webp",
        sourceType: "image/webp",
      },
      {
        media: "(max-width: 640px)",
        imageUrl: "/assets/images/massages-in-kampen.webp",
        sourceType: "image/webp",
      },
    ],
    defaultImageUrl: "/assets/images/massages-beauty-balance-header.jpg",
    alt: "Massages bij Beauty Balance vanaf 22,50",
  };

const PrijslijstPage : React.FC<PrijslijstPageProps> = (props: PrijslijstPageProps) => {
    const [pricelistBehandelingen, setPricelistBehandelingen] = useState<PriceListProps|null>();
    useEffect(() => {
       getBehandelingenProducts().then(
           (x) => {
               const prices = x.map((a) => {
                    return {
                        name: a.title,
                        nameClasses:"inline-block full",
                        price: a.price.toString(),
                        priceClasses:"font-Primary text-2xl"
                    };
                });
                setPricelistBehandelingen({ prices: prices});
           }
       )
    },[]);
    return (<>
    <TitleTag
        title="Prijslijst Behandelingen"
        canonical="https://www.tiana.nu/gezichtsbehandeling/prijslijst"
        description="Prijslijst van alle gezichtsbehandelingen bij schoonheidspecialiste Tiana in Kampen op een rij bij Beauty balance, schoonheidssalon is gevestigd Motet 24 Kampen"
      />
      <div className="w-full relative">
        <Picture
          imageClass="w-full"
          defaultImageUrl={pageHeader.defaultImageUrl}
          alt="Epileren, verven, harsen"
          width="100%"
          height="100%"
          images={pageHeader.images}
        ></Picture>
        <div className="absolute bottom-0 left-0 flex p-4 bg-lightviolet w-full">
          <h1 className="text-4xl pt-4 sm:text-3xl md:text-5xl m-2 font-Primary text-white font-normal">
            Prijslijst
          </h1>
        </div>
      </div>
      <section className="container ml-auto mr-auto p-4 pricelist">
          <h2>Behandelingen</h2>
        {pricelistBehandelingen && <PriceList {... pricelistBehandelingen} ></PriceList>}
     </section>
    </>);
}

export default PrijslijstPage;