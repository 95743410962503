import React from 'react';
import Titleblock, { TitleLevel } from './Titleblock';

export interface SloganProps
{
    title:string;
    titleLevel: TitleLevel;
    titleClassName?: string;
}

const Slogan = (props: SloganProps) => {
return (<>
        <Titleblock className={props.titleClassName} title={props.title} titleLevel={props.titleLevel} ></Titleblock>
        <img className="image-center" height="22" width="50" src="/assets/images/infinite-icon.png" alt="oneindig mooi bij Beauty Balance" />
    </>);
}

export default Slogan;