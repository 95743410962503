import React from 'react';
import Link from './Link';
import BorderPicture, { BorderPictureProps } from './BorderPicture';

export interface CardOverlayProps
{
    picture: BorderPictureProps;
    title: string;
    content?:string;
    actionUrl?:string;
    actionName: string;
}

const CardOverlay = (props: CardOverlayProps) => {
    return (<li className=" m-2 lg:m-10 p-1 text-center text-gray-400">
                <div className="justify-around bg-white">
                <BorderPicture {...props.picture}></BorderPicture>
                <h2 title={props.title} className="text-lg md:text-base xl:text-xl font-bold text-black uppercase my-4" dangerouslySetInnerHTML={{ __html: props.title }}></h2>
                {props.content && <p>{props.content}</p>}
                {props.actionUrl && 
                    <Link classname="bg-purple text-white text-center text-xs p-2 uppercase text-opacity-50 block" target="" url={props.actionUrl} name={props.actionName} title={props.actionName}></Link>
                }
                </div>
            </li>)
}

export default CardOverlay;