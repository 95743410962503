import React, { useState } from 'react';
import { CartOverviewProps } from '../Components/Cart/CartOverview';
import CartOverview from '../Components/Cart/CartOverview';
import { ProductCatalogItemProps } from '../Components/ProductCatalogItem';
import Titleblock from '../Components/Titleblock';
import ContactForm from '../Components/ContactForm';
import { sendMail } from '../Services/mailService';
import { useLocation } from 'react-router-dom';
import { ContactPageProps } from './ContactPage';
import { toDutchCurrency } from '../Services/general/currencyService';

export interface WinkelwagenPageProps
{
    cart: CartOverviewProps;
    addProductToCart(item:ProductCatalogItemProps): void;
    removeProductFromCart(item:ProductCatalogItemProps): void;
}

const WinkelwagenPage : React.FC<WinkelwagenPageProps> = (props: WinkelwagenPageProps) => {
    let name = "";
    let email = "";
    let remark = "";
    let phone = "";
    const location = useLocation();
    if (location && location.state) {
      var contactPageProps = location.state as ContactPageProps;
      name = contactPageProps?.name ?? "";
      email = contactPageProps?.email ?? "";
      remark = contactPageProps?.remark ?? "";
      phone = contactPageProps?.phone ?? "";
    }
    const [contactForm, setContactForm] = useState({
      name: name,
      email: email,
      phone: phone,
      remark: remark,
    });

    function getProducts() {
        return props.cart.cartProducts.map(x => `${x.product.title} - ${x.product.subTitle} ${x.quantity}x a ${toDutchCurrency(x.product.price)} = ${toDutchCurrency(x.product.price * x.quantity)} <br/>`);
    }
  
    const handleInputChange = (name: string, value: string) => {
      const updatedContact = { ...contactForm, [name]: [value] };
      setContactForm(updatedContact);
    };
    const submitContactForm = (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      if (
        contactForm.name == null ||
        contactForm.name === "" ||
        contactForm.name === "undefined" ||
        !contactForm.name ||
        contactForm.name[0] === "" ||
        contactForm.email == null ||
        contactForm.email === "" ||
        contactForm.email === "undefined" ||
        !contactForm.email ||
        contactForm.email[0] === "" ||
        contactForm.remark == null ||
        contactForm.remark === "" ||
        contactForm.remark === "undefined" ||
        !contactForm.remark ||
        contactForm.remark[0] === ""
      ) {
        return;
      } else {
        sendMail({
          content: {
            subject: "Product bestelling vanaf website",
            bodyHtml: `Bestelling vanaf website:<br/>"Van:${contactForm.name}<br/>Email:${contactForm.email}<br/>Telefoon:${contactForm.phone}<br/><p><h2>Uw producten:</h2>${getProducts()}</p><br/>Opmerking: ${contactForm.remark}`
          },
          from: { name: "info@tiana.nu", address: "info@tiana.nu" },
          to: [{ name: "info@tiana.nu", address: "info@tiana.nu" }],
        }).then( x=> {
            setContactForm({ name: "", email: "", remark: "", phone: "" });
            localStorage.removeItem("winkelwagen");
            props.cart.cartProducts.forEach(x=> props.removeProductFromCart(x.product));
            document.location.href = "/bedankt";
        }
        );
      }
    };
    
    return ( 
        <section className="container ml-auto mr-auto lg:p-4">
            
            <Titleblock titleLevel="h1" title="Uw gekozen producten:"></Titleblock>
            <div className="p-2">
            <CartOverview cartProducts={props.cart.cartProducts} addProductToCart={props.addProductToCart} removeProductFromCart={props.removeProductFromCart} ></CartOverview>
            </div>
            <div className="p-2 text-sm"><em>U kunt uw producten betalen bij het ophalen, contant of met pin.</em></div>
            <ContactForm title="Adres gegevens" confirmationTitle="Bestel" remarkField="Opmerking:"
              {...contactForm}
              onSubmit={submitContactForm}
              onInputChange={handleInputChange}
            ></ContactForm>
        </section>
        )
}

export default WinkelwagenPage;


