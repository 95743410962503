import { PriceListProps } from "../Components/PriceList"

export const epilerenPrizes = () : PriceListProps => {
    return {
        prices: [{ name: "Wenkbrouwen epileren", price: "€ 16,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Wenkbrauwen verven", price: "€ 14,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl"},
        { name: "Wimpers verven", price: "€ 14,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Wenkbrauwen en wimpers verven", price: "€ 25,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" }
    ]
    }
}
export const harsenGelaatPrizes = () : PriceListProps => {
    return {
        prices: [{ name: "Bovenlip", price: "€ 13,50", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Kin", price: "€ 13,50", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl"},
        { name: "Bovenlip en kin", price: "€ 25,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" }
    ]
    }
}
export const harsenLichaamPrizes = () : PriceListProps => {
    return {
        prices: [{ name: "Bikinilijn", price: "€ 25,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Onderbenen", price: "€ 30,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl"},
        { name: "Oksels", price: "€ 20,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Gehele Benen", price: "€ 50,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Rug", price: "€ 35,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" }
    ]
    }
}
export const microdermabrasiePrizes = () : PriceListProps => {
    return {
        prices: [{ name: "Deel van het gelaat", price: "€ 30,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl background-grey" },
        { name: "Meerdere delen van het gelaat", price: "€ 50,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl background-grey"},
        { name: "het hele gelaat", price: "€ 65,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl background-grey" },
    ]
    }
}
