import React from "react";
import Link from "./Link";
interface HeaderProps {
  menuitems: MenuItem[];  
  children?: React.ReactNode;
}

export interface MenuItem{
  name:string;
  title:string;
  url:string;
  children: MenuItemGroup[];
}

export interface MenuItemGroup{
  headerName: string;
  menuItems: MenuItem[];
}

const scrollListener = () => {
  const navbar = window.document.getElementById("menuheader");
  if (navbar == null) return;
  if (window.pageYOffset >= 30) {
    navbar.classList.add("sticky");
  } else {
    navbar.classList.remove("sticky");
  }
};


const Header = (props: HeaderProps) => {
  React.useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  });
  return (
    <div id="menuheader" className="bg-white header menushadow z-50">
      <header  className="container ml-auto mr-auto flex flex-nowrap items-center">
        <nav className="flex flex-grow">
          <div className="flex flex-1">
            <a href="/" title="naar homepage Beauty Balance">
            <img
              src="/assets/images/beauty-balance-logo.png"
              className="p-4"
              height="50"
              width="139"
              title="Beaty Balance Kampen"
              alt="Beauty Balance, schoonheidsspecialist in Kampen"
            /></a>
          </div>
            <label htmlFor="menu-toggle" className="lg:hidden mt-6 mr-2 lg:mr-0"><img src="/assets/images/menu.svg" width="24" height="24" alt="menu"/></label>
            <input type="checkbox" id="menu-toggle" className="hidden" />
            <ul role="menu" id="menu" className="hidden lg:flex lg:items-center justify-between lg:w-auto w-full">
              { props.menuitems.map((a,index)=> {
                   if (a.children == null || a.children.length === 0) { 
                     return (
                      <li className="uppercase my-2 lg:my-0" key={index}>
                        <Link
                          title={a.title}
                          target=""
                          name={a.name}
                          classname="p-4"
                          url={a.url}
                        ></Link>
                        </li>
                      )
                }else{
                  return (
                    <li className="hoverable uppercase my-2 lg:my-0 hover:bg-bgmenucolor hover:text-white" key={index}>
                        <span title={a.title} className="relative text-black block px-4 lg:p-6 lg:text-base hover:text-white">{a.name}</span>
                        <div role="toggle" className="z-50 p-6 mega-menu mb-16 sm:mb-0 shadow-xl topheader-gradient">
                        <div className="container mx-auto w-full flex flex-wrap justify-between">
                          {a.children.map((b)=> (
                            <ul key={b.headerName} className="px-4 w-1/2 md:w-1/4 border-gray-600 border-b sm:border-r lg:border-b-0 pb-6 pt-6 lg:pt-3">
                            <h3>{b.headerName}</h3>
                              {b.menuItems.map((c, index2) => ( 
                              <li key={index2} className="normal-case">
                                <Link
                                  title={c.title}
                                  target=""
                                  name={c.name}
                                  classname="block md:p-3 text-black"
                                  url={c.url}
                                ></Link>
                              </li>))
                              }
                            </ul>
                          ))}
                          </div>
                          </div>  
                    </li>
                  )
                }
              })
            }
              </ul>
        </nav>
            {props.children}
      </header>
    </div>
  );
};

export default Header;
