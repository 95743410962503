import React from "react";

export interface TitleTagProps {
  title: string;
  description?: string;
  canonical?: string;
  date?: string;
}

class TitleTag extends React.Component<TitleTagProps> {
  componentDidMount() {
    document.title = `${this.props.title} - Beauty Balance`;
    const descriptionElement = this.getElementByNameAndValue("META", "description")
    if (this.props.description != null && descriptionElement) {
      descriptionElement.attributes[1].value = `${this.props.description}`;
    }
    if (this.props.canonical) {
      document.getElementsByTagName(
        "link"
      )[1].attributes[1].value = `${this.props.canonical}`;
    }
    const element = this.getElementByNameAndValue("META", "date")
    if (this.props.date && element) {
      element.attributes[1].value = this.props.date;
    }else if (element)
    {
        const x = new Date();
        const days=x.getDate();
        const month = x.getMonth();
        const year = x.getFullYear();
        element.attributes[1].value = `${days}-${month}-${year}`;
    }
  }

  render() {
    return <></>;
  }

  getElementByNameAndValue = (tagname :string, value: string) : Element | undefined =>{
    let metas = document.getElementsByTagName(tagname);
    for (let index = 0; index < metas.length; index++) {
      let element = metas[index];
      if (element.attributes[0].value === value) {
        return element;
      }
    }
  }
}

export default TitleTag;
