import React from 'react';

interface BankProps
{
    KVK: string,
    Rek1: string,
    Rek2: string,
}

const Bank = (props: BankProps) => {
    return (<div className="m-2">
        <div>{props.KVK}</div>
        <div>{props.Rek1}</div>
        <div>{props.Rek2}</div>
    </div>);
}

export default Bank;