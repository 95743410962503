import { CardOverlayProps } from "../Components/CardOverlay";

const productCategoryItems = (): CardOverlayProps[] =>{
    let items : CardOverlayProps[] = new Array<CardOverlayProps>();
    
    items.push({ title:"Gezichts-<br/>behandelingen", 
            actionName:"vanaf EUR 37,50", 
            actionUrl:"/gezichtsbehandeling", picture: {
                picture : {
                    images: [{ media: "(min-width: 200px)", imageUrl: "/assets/images/gezichtsbehandeling-tiana-kampen.webp", sourceType: "image/webp" }],
                    alt: "IPL - ontharing, gezichtsbehandelingen",
                    width:"370",
                    height:"300",
                    lazy:false,
                    defaultImageUrl : "/assets/images/gezichtsbehandeling-tiana-kampen.jpg"
                }

    }});
    items.push({ title:"Wenkbrauwen<br/>epileren", 
    actionName:"vanaf EUR 11,50", 
    actionUrl:"/epileren-verven-harsen", picture: {
        picture : {
            images: [{ media: "(min-width: 300px)", imageUrl: "/assets/images/epileren-beauty-balance-kampen-verven.webp", sourceType: "image/webp" }],
            alt: "Wenkbrauwen epileren door Tiana bij Beauty Balance, Motet 24 Kampen",
            width:"370",
            height:"300",
            lazy:false,
            defaultImageUrl : "/assets/images/epileren-beauty-balance-kampen-verven.jpg"
        }
    }});
    items.push({ title:"IPL<br/>behandelingen",
    actionName:"vanaf EUR 25,00", 
    actionUrl:"/ipl-duurzaam-ontharen", picture: {
        picture : {
            images: [{ media: "(min-width: 300px)", imageUrl: "/assets/images/ipl-vanaf-25-euro.webp", sourceType: "image/webp"  }],
            alt: "ipl vanaf 25 euro",
            width:"370",
            height:"300",
            lazy: false,
            defaultImageUrl : "/assets/images/ipl-vanaf-25-euro.jpg"
        }
    }});
    items.push({ title:"Ontspannende<br/>Massages", 
    actionName:"vanaf EUR 25,00",
    actionUrl:"/massages", picture: {
        picture : {
            images: [{ media: "(min-width: 300px)", imageUrl: "/assets/images/massage-in-kampen.webp",sourceType: "image/webp"  }],
            alt: "massage bij beauty balance in kampen",
            width:"370",
            height:"300",
            lazy:false,
            defaultImageUrl : "/assets/images/massage-in-kampen.jpg"
        }
    }});
    return items;
}

export default productCategoryItems;