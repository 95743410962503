import React from 'react';
import Titleblock, { TitleLevel } from "./Titleblock";

export interface TextBlockProps
{
        title: string;
        titleLevel: TitleLevel;
        titleClassName?: string | null;
        text:string;
        className? :string;
}

export type ImageAlign = "left" | "right";

const TextBlock: React.FC<TextBlockProps>  = (props: TextBlockProps) => {
    let classes: string = "p-2 md:p-8";
    return (
            <div className={`${classes} ${props.className || ''}`}>    
                <Titleblock titleLevel={props.titleLevel} title={props.title} className={props.titleClassName ?? null}></Titleblock>
                <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
            </div>
        );
}

export default TextBlock;