import React from 'react';
import TopHeader from './TopHeader';
import Header, { MenuItem } from './Header';

interface PageHeaderProps
{
    menuItems : MenuItem[];
    children?: React.ReactNode;
}

const PageHeader = (props: PageHeaderProps) => {
    return (
        <><TopHeader></TopHeader>
        <Header menuitems={props.menuItems} children={props.children}></Header></>
    );
}

export default PageHeader;