
import { PriceListProps } from "../Components/PriceList";

export const ipl_gezichtPrizes = () : PriceListProps => {
    return {
        prices: [{ name: "Bovenlip", price: "€ 25,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Kin", price: "€ 30,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl"},
        { name: "Bovenlip en kin", price: "€ 45,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Hals", price: "€ 50,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Gehele gezicht", price: "€ 95,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" }]
    }
}
export const ipl_lichaamPrizes = () : PriceListProps => {
    return {
        prices: [{ name: "Nek", price: "€ 40,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Schouders", price: "€ 80,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl"},
        { name: "Oksel", price: "€ 50,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Rug / boven", price: "€ 90,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Rug / onder", price: "€ 90,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Rug / geheel", price: "€ 150,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Borst man", price: "€ 75,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Buik", price: "€ 75,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Bikinilijn", price: "€ 60,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Bikinilijn volledig", price: "€ 80,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
    ]
    }
}
export const ipl_ledematenPrizes = () : PriceListProps => {
    return {
        prices: [{ name: "Bovenarmen", price: "€ 70,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Onderarmen", price: "€ 60,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl"},
        { name: "Armen volledig", price: "€ 120,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Bovenbenen", price: "€ 175,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Onderbenen", price: "€ 150,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" },
        { name: "Benen volledig", price: "€ 275,00", nameClasses:"inline-block full", priceClasses:"font-Primary text-2xl" }]
    }
}