import Picture from "./Picture/Picture";
import React from 'react';
import Titleblock from "./Titleblock";
import { ImageTextBlockProps } from "./ImageTextBlockProps";

export type ImageAlign = "left" | "right";

const ImageTextOverlayBlock = (props: ImageTextBlockProps) => {

    return (
            <div className={props.sectionCssClass ?? "relative"}> 
           
                <Picture {...props.picture}></Picture>
               
                <article className={props.paragaphcssClass ?? "" }>
                    {props.title && <Titleblock titleLevel={props.titleLevel} title={props.title}></Titleblock>}
                    <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
                </article>
                
            </div>
        );
}

export default ImageTextOverlayBlock;