import React from 'react';
import TextBlock from '../Components/TextBlock';
import Picture from '../Components/Picture/Picture';
import PriceList, { PriceListProps } from '../Components/PriceList';
import Slogan from '../Components/Slogan';
import TitleTag from '../Components/TitleTag';
import { PictureProps } from '../Components/Picture/PictureProps';
export interface LandingPageProps
{
    url?:string
    title:string,
    description: string,
    canonical?:string,
    content:string
    topImage?: PictureProps;
    prizes?: PriceListProps
}

const LandingPage = (props: LandingPageProps) => {
    
    let prizesComp;
    if (props.prizes)
    {
        prizesComp = (
            <div className="bg-grey">
            <Slogan titleLevel='h2' titleClassName="text-center pt-10 my-6" title="Prijslijst"></Slogan>
            <section className="container ml-auto mr-auto pricelist pb-6">   
                        <PriceList {... props.prizes} />
                        </section>
            </div>
        );
    }
    let canonical: string = "";
    if (!props.canonical)
    {
        canonical = "https://www.tiana.nl" + props.url;
    }else{
        canonical = props.canonical;
    }
    if (props.topImage)
    {
        return (
            <>
            <TitleTag title={props.title} description={props.description} canonical={canonical}></TitleTag>
            <div className="w-full relative">
                <Picture imageClass="w-full" defaultImageUrl={props.topImage.defaultImageUrl} alt={props.topImage.alt} images={props.topImage.images}></Picture>
                <div className="absolute bottom-0 left-0 flex p-4 bg-lightviolet w-full">
                    <h1 className="text-4xl pt-4 sm:text-3xl md:text-5xl m-2 font-Primary text-white font-normal">{props.title}</h1>
                </div>
            </div>
            <section className="container ml-auto mr-auto lg:p-4 xl:w-3/4">
            <p dangerouslySetInnerHTML={{ __html: props.content }}></p>
            {prizesComp}
            </section>
            </>
        )
    }else{
        return (
            <>
            <TitleTag title={props.title} description={props.description} canonical={canonical}></TitleTag>
            <TextBlock titleLevel='h1' title={props.title} text={props.content} ></TextBlock>
            {prizesComp}
            </>
        );
    }
}

export default LandingPage;